<template>
  <div class="base-tooltip">
    <div class="base-tooltip__action">
      <slot />
    </div>
    <div
      ref="tooltip"
      class="base-tooltip__wrapper"
      :class="[background, 'base-tooltip__wrapper--'+ direction]"
    >
      <div class="base-tooltip__pointer" />
      <p class="base-tooltip__text" :class="[color]">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      direction: 'ltr'
    }
  },
  mounted () {
    const r = this.$refs.tooltip.getBoundingClientRect()
    if (r.right > 330 && window.innerWidth < 600) {
      this.direction = 'rtl'
    }
  }
}
</script>

<style scoped lang="scss">
.base-tooltip {
  @apply relative;
  &__wrapper {
    @apply  invisible transition absolute z-50 bg-white shadow px-2 py-1 rounded-lg w-[max-content];
    &--ltr {
      @apply -left-1;
      &::after{
        left: 28px;
      }
    }
    &--rtl {
      @apply -right-2;
      &::after{
        right: -4px;
      }
    }
    top: calc(100% + 10px);
    &::after{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      box-sizing: border-box;
      border: 0.5em solid black;
      border-color: transparent transparent white white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -1px 1px 1px 0 rgba(228 224 224 / 40%);
    }
  }
  &:hover {
    .base-tooltip__wrapper {
      @apply visible;
    }
  }
  &__action {
    @apply cursor-pointer flex items-center;
  }
  &__text {
    @apply text__caption-xs--semibold lg:text__body-3-md--regular;
  }
}
</style>
