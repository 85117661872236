<template>
  <div>
    <div v-if="show" class="absolute top-[40%] inset-x-0 mx-auto w-[40px] z-20" :class="[mainClass]">
      <BaseLoadingSpinner />
    </div>
    <div :class="[slotWrapperClass,{'blur-sm': show}]">
      <slot />
    </div>
  </div>
</template>

<script>
import BaseLoadingSpinner from '@library/base/loading/loading-spinner.vue'
export default {
  name: 'BlockLoadingBlur',
  components: {
    BaseLoadingSpinner
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    mainClass: {
      type: String,
      default: ''
    },
    slotWrapperClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
