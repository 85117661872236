// TODO : move to public mixins folder
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('dialog', ['allDialogs']),
    ...mapGetters('authentication', ['getActionAfterLogin'])
  },
  methods: {
    ...mapActions('authentication', ['saveActionAfterLogin']),
    ...mapActions('liveChat', ['showLiveChat']),
    action (redirectUrl = '/') {
      const actionAfterLogin = this.getActionAfterLogin
      if (!actionAfterLogin.type) {
        window.location.href = redirectUrl
      }
      setTimeout(() => {
        if (actionAfterLogin.type === 'redirect') {
          this.$router.push(this.localePath({
            name: actionAfterLogin.target
          }))
        }
        if (actionAfterLogin.type === 'dialog') {
          this.$nuxt.$emit(actionAfterLogin.target)
        }
        if (actionAfterLogin.type === 'message') {
          this.showLiveChat(actionAfterLogin.target)
        }
      }, 200)
      this.saveActionAfterLogin({ type: '', target: '' })
    }
  }
}
