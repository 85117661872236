import { cleanObject } from '@helper/utils'
import {
  STORE_COURSE_GROUP,
  STORE_IMPORTANT_CITIES,
  STORE_LIST_PRICE_CHART,
  STORE_LIST_SCHEMA_RATING,
  UPDATE_LIST_PARAMS,
  UPDATE_LIST_QUERIES,
  STORE_LIST_FILTERS,
  STORE_LIST_SORTS,
  RESET_LIST_QUERIES,
  RESET_LIST_PARAMS,
  STORE_WORLD_COUNTRIES,
  STORE_SHOW_TEACHERS_COUNT,
  STORE_SHOW_TOTAL,
  STORE_SHOW_SORTS, STORE_LIST_DESIGN_COLOR, STORE_LIST_TITLE, STORE_HOME_START_TEACHING
} from '../mutation-types'
export default {
  [STORE_COURSE_GROUP] (state, payload) {
    state.courseGroups = payload
  },
  [STORE_IMPORTANT_CITIES] (state, payload) {
    state.importantCities = payload
  },
  [STORE_LIST_SCHEMA_RATING] (state, payload) {
    state.schemaRating = payload
  },
  [STORE_LIST_PRICE_CHART] (state, payload) {
    state.priceChart = payload
  },
  [STORE_LIST_FILTERS] (state, payload) {
    state.filters = payload
  },
  [STORE_LIST_SORTS] (state, payload) {
    state.listSorts = payload
  },
  [RESET_LIST_QUERIES] (state, payload) {
    state.listQueries = payload
  },
  [RESET_LIST_PARAMS] (state, payload) {
    state.listParams = payload
  },
  [UPDATE_LIST_QUERIES] (state, payload) {
    state.listQueries = cleanObject({ ...state.listQueries, ...payload })
  },
  [UPDATE_LIST_PARAMS] (state, payload) {
    state.listParams = { ...payload }
  },
  [STORE_WORLD_COUNTRIES] (state, payload) {
    state.worldCountries = payload
  },
  [STORE_SHOW_TEACHERS_COUNT] (state, payload) {
    state.show_teachers_count = payload
  },
  [STORE_SHOW_TOTAL] (state, payload) {
    state.show_total = payload
  },
  [STORE_SHOW_SORTS] (state, payload) {
    state.show_sorts = payload
  },
  [STORE_LIST_DESIGN_COLOR] (state, payload) {
    state.tutorListDesignColor = payload
  },
  [STORE_LIST_TITLE] (state, payload) {
    state.listTitle = payload
  },
  [STORE_HOME_START_TEACHING] (state, payload) {
    state.startTeaching = payload
  }

}
