<template>
  <div>
    <div class="lg-block-hidden p-4 lg:p-8 pb-0 lg:pb-0">
      <div class="flex items-center space-x-2">
        <BaseIcon
          class="cursor-pointer"
          name="clear"
          size="lg"
          color="label-text"
          @click="hide()"
        />
        <p v-if="titleHeader" class="card-dialog__header--title">
          {{ titleHeader }}
        </p>
      </div>
      <slot />
    </div>
    <div class="lg-hidden-block">
      <div v-if="headerType === 'line'" class="text-center p-4 lg:p-8">
        <div class="card-dialog__header--line" @click="hide()" />
      </div>
      <div
        v-else
        class="text-center relative py-[14px] rounded-b-2xl shadow-banner px-4 lg:px-8"
      >
        <BaseIcon
          class="cursor-pointer absolute left-4 top-2"
          name="clear"
          size="lg"
          color="label-text"
          @click="hide()"
        />
        <p class="card-dialog__header--title min-h-[16px]">
          <span v-show="titleHeader">{{ titleHeader }}</span>
        </p>
      </div>
      <div class="px-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    titleHeader: {
      type: String,
      default: ''
    },
    headerType: {
      type: String,
      default: 'line'
    }
  },
  methods: {
    hide () {
      this.$emit('hide')
    }
  }
}
</script>

<style scoped>

</style>
