<template>
  <h3 class="text-center text__headline-3-xs--semibold">
    {{ title }}
  </h3>
</template>

<script>
export default {
  name: 'BaseTitleAuth',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
