import { render, staticRenderFns } from "./icon-bg.vue?vue&type=template&id=13c0e2e5&scoped=true&"
import script from "./icon-bg.vue?vue&type=script&lang=js&"
export * from "./icon-bg.vue?vue&type=script&lang=js&"
import style0 from "./icon-bg.vue?vue&type=style&index=0&id=13c0e2e5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c0e2e5",
  null
  
)

export default component.exports