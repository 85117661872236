<template>
  <div class="auth-layout">
    <div class="auth-layout__content">
      <div>
        <a class="auth-layout__back-link lg-flex-hidden" href="/">
          <BaseIcon name="chevron_left" color="label-text" classes="hover--current" />
          <span>{{ $t('common.actions.home') }}</span>
        </a>
        <SectionAuthCard>
          <Nuxt />
        </SectionAuthCard>
      </div>
    </div>
  </div>
</template>

<script>

import SectionAuthCard from '@design-system/section/auth/auth-card'
import connectionCheck from '@helper/connection-check'
export default {
  name: 'AuthLayout',
  components: {
    SectionAuthCard
  },
  mixins: [connectionCheck]
}
</script>
<style lang="scss">
.auth-layout{
  @apply w-full h-full absolute;
  &__content {
    @apply lg:flex justify-center items-center w-full h-full;
  }
  &__back-link {
    @apply items-center space-x-2 cursor-pointer hover:text-primary mb-6;
    &:hover {
      > span {
        @apply text-primary;
      }
    }
  }
}
</style>
