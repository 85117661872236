<template>
  <div class="flex flex-col" style="min-height: 100vh">
    <SectionHeader />
    <Nuxt />
    <SectionFooter v-if="showFooter" />
    <footer v-else class="empty-footer" />
    <SectionAuthDialogSetup />
    <script v-if="schemaExist" type="application/ld+json" v-html="getMeta.schema" />
  </div>
</template>

<script>
import SectionHeader from '@design-system/section/header/header.vue'
import SectionFooter from '@design-system/section/footer/footer.vue'
import SectionAuthDialogSetup from '@design-system/section/auth/auth-dialog/auth-dialog-setup'
import connectionCheck from '@helper/connection-check'
import { mapGetters } from 'vuex'

export default {
  name: 'MainLayout',
  components: {
    SectionAuthDialogSetup,
    SectionHeader,
    SectionFooter
  },
  mixins: [connectionCheck],
  data () {
    return {
      footerLessPages: ['start-teaching/steps', 'start-teaching/ticket/list', 'tutor-request',
        'start-teaching/ticket/new'],
      showFooter: true
    }
  },
  computed: {
    ...mapGetters('global', ['getMeta']),
    schemaExist () {
      return this?.getMeta?.schema
    }
  },
  watch: {
    '$route' () {
      this.checkPath()
    }
  },
  created () {
    this.checkPath()
  },
  methods: {
    checkPath () {
      this.showFooter = true
      for (const i in this.footerLessPages) {
        if (this.$router.currentRoute.fullPath.includes(this.footerLessPages[i])) {
          this.showFooter = false
        }
      }
    }
  }
}
</script>
<style lang="scss">
.empty-footer {
  @apply w-full min-h-[100px] lg:h-0;
}
</style>
