export default function () {
  return {
    chatLIst: [],
    unreadMessage: 0,
    conversationSelected: {
      chat_username: ''
    },
    messageList: {
      chat_messages: []
    },
    liveChatVisibility: {
      visible: false,
      type: '',
      chatUserName: ''
    },
    liveChatLoading: false,
    presenceStatus: false,
    moreMessageLoading: false
  }
}
