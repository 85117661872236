import {
  STORE_CHAT_LIST,
  STORE_UNREAD_MESSAGE,
  STORE_MESSAGE_LIST,
  STORE_LIVE_CHAT_LOADING,
  STORE_MESSAGE_LIST_CONVERSATION_SELECTED,
  STORE_LIVE_CHAT_VISIBILITY,
  STORE_NEW_MESSAGE,
  UPDATE_CHAT_LIST_UNREAD_MESSAGE, STORE_PRESENCE_STATUS, STORE_STREAM_MESSAGE, STORE_MORE_MESSAGE_LOADING
} from '../mutation-types'
export default {
  [STORE_CHAT_LIST] (state, payload) {
    state.chatLIst = payload
  },
  [STORE_UNREAD_MESSAGE] (state, payload) {
    state.unreadMessage = payload
  },
  [STORE_MESSAGE_LIST] (state, payload) {
    if (Object.keys(state.messageList.chat_messages).length > 0 && payload.page > 1) {
      for (const messageDate in payload.chat_messages) {
        if (state.messageList.chat_messages[messageDate]) {
          state.messageList.chat_messages[messageDate].unshift(...payload.chat_messages[messageDate].reverse())
        } else {
          state.messageList.chat_messages =
              { [messageDate]: payload.chat_messages[messageDate].reverse(), ...state.messageList.chat_messages }
        }
      }
    } else {
      let reversedObj = {}
      for (const messageDate in payload.chat_messages) {
        reversedObj = { [messageDate]: payload.chat_messages[messageDate].reverse(), ...reversedObj }
      }
      payload.chat_messages = reversedObj
      state.messageList = payload
    }
  },
  [STORE_MESSAGE_LIST_CONVERSATION_SELECTED] (state, payload) {
    state.conversationSelected.chat_username = payload
  },
  [STORE_LIVE_CHAT_VISIBILITY] (state, payload) {
    state.liveChatVisibility = payload
  },
  [STORE_LIVE_CHAT_LOADING] (state, payload) {
    state.liveChatLoading = payload
  },
  [STORE_NEW_MESSAGE] (state, payload) {
    const messageDate = payload.chat_message.created_at.split(' ')[0]
    if (state.messageList.chat_messages[messageDate]) {
      state.messageList.chat_messages[messageDate].push(payload.chat_message)
    } else {
      state.messageList.chat_messages = { ...state.messageList.chat_messages, [messageDate]: [payload.chat_message] }
    }
  },
  [STORE_STREAM_MESSAGE] (state, payload) {
    if (state.messageList?.chat?.id === payload.chat.id) {
      this.commit('liveChat/STORE_NEW_MESSAGE', payload)
    }
  },
  [UPDATE_CHAT_LIST_UNREAD_MESSAGE] (state, payload) {
    if (state.chatLIst && state.chatLIst.chats && state.chatLIst.chats.length === 0) {
      state.chatLIst.chats.push(payload)
    } else {
      for (const x in state.chatLIst.chats) {
        if (state.chatLIst.chats[x].id === payload.id) {
          state.chatLIst.chats[x].unread = payload.unread
        }
      }
    }
  },
  [STORE_PRESENCE_STATUS] (state, payload) {
    state.presenceStatus = payload
  },
  [STORE_MORE_MESSAGE_LOADING] (state, payload) {
    state.moreMessageLoading = payload
  }
}
