/* -------------------- public Endpoints -------------------- */
import { Cache } from 'axios-extensions'
import { minuteToMilliSecond } from '@helper/utils'
export const HOME = { url: 'www/v1/home', cache: cache(60) }
export const FAQ = { url: '/www/v1/faq', cache: cache(60) }
export const AboutUs = { url: '/www/v1/about-us', cache: cache(60) }
export const ContactUs = { url: '/www/v1/contact-us', cache: cache(60) }
export const Policy = { url: '/www/v1/privacy-policy', cache: cache(60) }
export const Terms = { url: '/www/v1/terms', cache: cache(60) }
export const WORLD = { url: '/www/v1/world', cache: cache(60) }
export const SEARCH_COURSES = { url: '/www/v1/tutor/request/course-groups', cache: cache(60) }
export const SEARCH_COURSES_LIST = { url: '/www/v1/tutor/list/course-groups', cache: cache(60) }
export const TUTOR_LIST_FETCH = { url: 'www/v1/tutor/list/fetch', cache: cache(10) }
export const TUTOR_LIST_PRICE_CHART_FETCH = { url: 'www/v1/tutor/list/get-price-chart', cache: cache(15) }
export const TUTOR_PROFILE = { url: 'www/v1/tutor/profile/{username}', cache: cache(10) }
export const TUTOR_CALENDAR = 'www/v1/tutor/profile/calendar/{teacherId}'
export const TUTOR_COURSE_GROUP = { url: 'www/v1/tutor/profile/course-groups/{teacherId}', cache: cache(10) }
export const TUTOR_FEEDBACK = { url: 'www/v1/tutor/profile/feedbacks/{teacherId}', cache: cache(10) }
export const TUTOR_REQUEST_SELECT_TUTOR = { url: '/www/v1/tutor/request/select-tutor' }
export const REQUEST_COURSES = { url: '/www/v1/tutor/request/courses', cache: cache(10) }
export const REQUEST_PRICE = { url: '/www/v1/tutor/request/price/', cache: cache(10) }
export const REQUEST_LANGUAGES = { url: '/www/v1/tutor/request/language/', cache: cache(10) }
export const REQUEST_CALENDAR = { url: '/www/v1/tutor/request/calendar/', cache: cache(10) }
export const REQUEST_CALENDAR_DAYS = { url: '/www/v1/tutor/request/calendar-days', cache: cache(10) }
export const REQUEST_DESCRIPTION = { url: '/www/v1/tutor/request/description/', cache: cache(10) }
export const REQUEST_TRACKING = { url: '/www/v1/tutor/request/tracking/', cache: cache(10) }
export const REQUEST_CANCEL = { url: '/www/v1/tutor/request/cancel', cache: cache(10) }
export const REQUEST_CONFIRM = { url: '/www/v1/tutor/request/confirm/', cache: cache(10) }
export const REQUEST_FORM = { url: '/www/v1/tutor/request/form', cache: cache(10) }
export const REQUEST_FINISH = { url: '/www/v1/tutor/request/finish/', cache: cache(10) }

function cache (time) {
  return new Cache({ ttl: minuteToMilliSecond(time), size: 5000, ttlAutopurge: true })
}
