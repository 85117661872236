export function getLanguage (state) {
  return state.languageState
}
export function getCourseId (state) {
  return state.courseId
}
export function getLoadingList (state) {
  return state.loadingList
}
export function getTypeSelected (state) {
  return state.typeSelected
}
export function getLanguagesList (state) {
  return state.languagesList
}
export function getActiveReturn (state) {
  return state.activeReturn
}
export function getParent (state) {
  return state.parent
}
export function getCourses (state) {
  return state.courses
}
