// auth endpoints
import { Cache } from 'axios-extensions'
import { minuteToMilliSecond } from '../helper/utils'
export const EMAIL_REGISTRATION_CONFIRMATION = 'auth/v1/email-registration-confirmation'
export const LOGIN_BY_PASSWORD = '/auth/v1/login-by-password'
export const EMAIL_VERIFICATION_SEND_CODE = '/auth/v1/email-verification-send-code'
export const LOGIN_BY_EMAIL_VERIFICATION = '/auth/v1/login-by-email-verification'
export const LOGIN_BY_SHORTCUT_LINK = '/auth/v1/login-by-shortcut-link'
export const REGISTER_FULL_NAME = '/auth/v1/register-fullname'
export const REGISTER_INFO = '/auth/v1/register-info'
export const SET_PASSWORD = '/auth/v1/set-password'
export const ME = '/auth/v1/me'
export const LOGOUT = '/auth/v1/logout'
export const OAUTH_PROVIDER = '/auth/v1/get-oauth-providers'
export const OAUTH_LINK = '/auth/v1/login-by-oauth'
export const LOGIN_BY_OAUTH = '/auth/v1/login-by-oauth-callback'
export const LOGIN_TEACHER_BY_LINK = '/auth/v1/start-teaching'
export const UPLOAD_FILE = '/file/v1/upload-tus'
export const BEST_TEACHERS = { url: 'www/v1/home/best-teachers', cache: cache(15) }
export const LIVECHAT_CHAT_LIST = { url: '/chat/v1/list', cache: cache(10) }
export const LIVECHAT_UNREAD_MESSAGE = { url: '/chat/v1/unread-count', cache: cache(10) }
export const LIVECHAT_MESSAGE_LIST = { url: '/chat/v1/message/list', cache: cache(10) }
export const LIVECHAT_CREATE_MESSAGE = '/chat/v1/message/new'
export const LIVECHAT_PRESENCE_STATUS = '/chat/v1/presence-status'
export const LIVECHAT_READ_MESSAGES = '/chat/v1/message/read'
export const TICKET_NEW = '/ticket/v1/create'
export const TICKET_LIST = '/ticket/v1/list'
export const TICKET_VIEW = '/ticket/v1/view/'
export const TICKET_REPLY = '/ticket/v1/reply'
export const MEGA_MENU = { url: '/www/v1/layout/menu', cache: cache(30) }
export const FOOTER = { url: '/www/v1/layout/footer', cache: cache(1440) }
function cache (time) {
  return new Cache({ ttl: minuteToMilliSecond(time), size: 5000, ttlAutopurge: true })
}
