<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NativeBack',
  props: {
    state: {
      type: Array,
      default: () => []
    },
    closeMethods: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      changeKeys: [],
      st: this.getDialog
    }
  },
  watch: {
    state (val, oldVal) {
      if (val.toString() === oldVal.toString()) { return oldVal }
      this.changeKeys = []
      for (let i = 0; i <= val.length; i++) {
        if (val[i] !== oldVal[i]) {
          if (val[i]) {
            this.pushState(i)
          } else {
            this.popState()
          }
        }
      }
    }
  },
  mounted () {
    window.addEventListener('popstate', this.popstateListener)
  },
  beforeUnmount () {
    window.removeEventListener('popstate', this.popstateListener)
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.popstateListener)
  },
  methods: {
    setState (val, oldVal) {
      if (val === oldVal) { return }
      if (val) {
        this.pushState()
      } else {
        this.popState()
      }
    },
    pushState (key) {
      this.changeKeys.push(key)
      window.history.pushState(null, null)
      if (process.env.NODE_ENV === 'development') {
        console.log('pushState')
      }
    },
    popState () {
      this.changeKeys.pop()
      window.history.back()
      if (process.env.NODE_ENV === 'development') {
        console.log('popState')
      }
    },
    popstateListener () {
      const key = this.changeKeys.pop()
      if (typeof key === 'number') {
        window.history.pushState(null, null)
        this.closeMethods[key]()
      } else {
        this.$emit('finishHiddenModal')
      }
    }
  }
}
</script>
