import * as commonRouter from '@helper/common-routes.js'
// import * as externalRouter from '@helper/get-link-external.js'
// import * as MainRouter from '@constructor/main-routers'
import * as Links from '@helper/common-links.js'
const routers = {
  ...commonRouter
  // ...MainRouter
  // ...externalRouter
}
const links = { ...Links }
export default function ({}, inject) {
  inject('links', links)
  inject('routes', routers)
}
