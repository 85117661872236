<template>
  <section class="conversation-row ">
    <div v-if="chatList.length > 0" class="scroll-custom">
      <div
        v-for="(chat,index) in chatList"
        :key="chat.id"
        @click="clickChat({chatUserName :chat.user.chat_username,e :$event})"
      >
        <div class="flex justify-between w-full mt-2">
          <div class="flex">
            <BaseAvatarConversation
              :img-url="picture(chat)"
              mode="conversation"
              class="mr-5"
            />
            <div class="conversation-row__details-section justify-center">
              <span class="conversation-row__teacher-name">{{ chat.user.fullname }}</span>
              <span class="conversation-row__second-font">{{ courseName(chat) }}</span>
            </div>
          </div>
          <div class="conversation-row__details-section justify-start">
            <span class="conversation-row__second-font">{{ timePassed(chat.created_at) }}</span>
            <span v-if="chat.unread > 0" class="conversation-row__message-count">{{ chat.unread }}</span>
          </div>
        </div>
        <hr v-if="index !== (chatList.length + 1)" class="bg-border-cart border-border-cart  mt-2 lg:mt-4 box-border">
      </div>
    </div>
    <ConversationPlaceholder v-else @closeDrawer="$emit('closeDrawer')" />
  </section>
</template>

<script>
import BaseAvatarConversation from '@design-system/base/avatar/avatar-conversation'
import { mapActions, mapGetters } from 'vuex'
import { calculateTime } from '@helper/utils'
import ConversationPlaceholder from './conversation-placeholder'

export default {
  name: 'SectionLiveChatConversationRow',
  components: { BaseAvatarConversation, ConversationPlaceholder },
  props: {
    showLine: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('liveChat', ['getChatList']),
    chatList () {
      return this.getChatList.chats || []
    }
  },
  methods: {
    ...mapActions('liveChat', ['fetchChatList']),
    clickChat (data) {
      this.$emit('selectChat', data)
    },
    picture (chatItem) {
      return chatItem?.user?.picture?.jpg_80X80?.url
    },
    timePassed (time) {
      return calculateTime(time)
    },
    courseName (chat) {
      return chat?.teacher_request?.course_name || ''
    }
  }
}
</script>

<style scoped lang="scss">
.conversation-row {
  @apply w-full cursor-pointer h-[81vh] pb-3 overflow-y-auto;
  &__teacher-name {
    @apply text__headline-5-xs--bold text-body-1;
  }

  &__second-font {
    @apply text__caption-xs--regular text-body-2;
  }

  &__message-count {
    @apply rounded-full text__body-3-md--regular w-[24px] h-[24px] bg-pink text-white mt-2
    self-end flex items-center justify-center;
  }

  &__details-section {
    @apply flex flex-col;
  }
}
</style>
