export default function () {
  return {
    requestRouteNameSteps: {
      COURSE: 'tutor-request-course',
      PRICE: 'tutor-request-price-id',
      LANGUAGE: 'tutor-request-languages-id',
      CALENDAR: 'tutor-request-calendar-id',
      DESCRIPTION: 'tutor-request-description-id',
      TRACKING: 'tutor-request-tracking-id'
    },
    submitRequestSteps: [
      {
        icon: 'local_library',
        color: 'white',
        smSize: 'sm',
        mdSize: 'lg',
        bgColor: 'orange'
      }, {
        icon: 'monetization_on',
        color: 'white',
        smSize: 'sm',
        mdSize: 'lg',
        bgColor: 'orange'
      }, {
        icon: 'signal_cellular_alt',
        color: 'white',
        smSize: 'sm',
        mdSize: 'lg',
        bgColor: 'orange'
      }, {
        icon: 'schedule',
        color: 'white',
        smSize: 'sm',
        mdSize: 'lg',
        bgColor: 'orange'
      }, {
        icon: 'border_color',
        color: 'white',
        smSize: 'sm',
        mdSize: 'lg',
        bgColor: 'orange'
      }
    ],
    requestTeacher: {
      teacher_request_id: null
    },
    submitRequestCalendar: {},
    priceStepData: {},
    languagesStepData: {},
    descriptionStepData: {},
    trackingData: {},
    selectedTutor: {},
    confirmData: {},
    formData: {},
    finishData: {}
  }
}
