export default function () {
  return {
    name: '',
    names: {
      TUTOR_LIST_REVIEW: 'TUTOR_LIST_REVIEW',
      TUTOR_LIST_GUIDE: 'TUTOR_LIST_GUIDE',
      TUTOR_LIST_SORT: 'TUTOR_LIST_SORT',
      SEARCH_COURSE: 'SEARCH_COURSE',
      MOBILE_FILTER: 'MOBILE_FILTER',
      MOBILE_FILTER_TUTOR_LIST: 'MOBILE_FILTER_TUTOR_LIST',
      AUTH_LOGIN: 'AUTH_LOGIN',
      AUTH_SIGNUP: 'AUTH_SIGNUP',
      WALLET_BALANCE: 'WALLET_BALANCE',
      AUTH_FORGET_PASSWORD: 'AUTH_FORGET_PASSWORD',
      CERTIFICATES_DELETE: 'CERTIFICATES_DELETE',
      CERTIFICATES_FORM: 'CERTIFICATES_FORM',
      EDUCATION_DEGREES_DELETE: 'EDUCATION_DEGREES_DELETE',
      WORK_EXPERIENCES_DELETE: 'WORK_EXPERIENCES_DELETE',
      CONFIRM_DELETE: 'CONFIRM_DELETE',
      CROPPER: 'CROPPER',
      TEACHER_ABOUT_EXAMPLE: 'TEACHER_ABOUT_EXAMPLE',
      TRACKING_EXPLAIN: 'TRACKING_EXPLAIN',
      NEW_TICKET: 'NEW_TICKET',
      TUTOR_REQUEST_COURSE_DIALOG: 'TUTOR_REQUEST_COURSE_DIALOG',
      TUTOR_REQUEST_COURSE_DIALOG_NOT_TEACHING: 'TUTOR_REQUEST_COURSE_DIALOG_NOT_TEACHING',
      TUTOR_REQUEST_CANCEL_REQUEST: 'TUTOR_REQUEST_CANCEL_REQUEST',
      TUTOR_REQUEST_CONFIRM_REQUEST: 'TUTOR_REQUEST_CONFIRM_REQUEST',
      SELECT_TUTOR: 'SELECT_TUTOR',
      SELECT_TUTOR_ERROR: 'SELECT_TUTOR_ERROR',
      PROFILE_SESSION_PACKAGES: 'PROFILE_SESSION_PACKAGES',
      TRACKING_SELECTED_TUTORS: 'TRACKING_SELECTED_TUTORS',
      WALLET_WITHDRAW: 'WALLET_WITHDRAW',
      WORK_EXPERIENCES_FORM: 'WORK_EXPERIENCES_FORM',
      EDUCATION_DEGREES_FORM: 'EDUCATION_DEGREES_FORM',
      SKIP_STEP: 'SKIP_STEP'

    }
  }
}
