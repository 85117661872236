<template>
  <section>
    <audio
      ref="correctSound"
      preload="auto"
    >
      <source :src="sound">
    </audio>
    <BaseDrawer ref="drawerComponent" :type="'chat'" @sidebarClosed="sidebarClosed()">
      <template #activator>
        <UnreadMessage @click.native="changeState" />
      </template>
      <template #close>
        <div v-if="showHeader" class="live-chat-header">
          <BaseIcon
            class="cursor-pointer absolute left-4 top-5 lg:top-0 lg:left-2"
            name="close"
            size="xs-md"
            color="color-icon"
          />
          <p class="title">
            {{ $t('common.actions.message') }}
          </p>
        </div>
      </template>
      <template #sidebar>
        <SectionLiveChatInbox class="p-4 lg:p-0" @closeDrawer="closeDrawer" />
      </template>
    </BaseDrawer>
  </section>
</template>

<script>
import SectionLiveChatInbox from '@design-system/section/live-chat/inbox/inbox'
import UnreadMessage from '@design-system/section/live-chat/unread-message/unread-message'
import { detectMobileScreen } from '@helper/utils'
import { mapActions, mapGetters } from 'vuex'
import { PusherModule } from '@helper/pusher.js'
export default {
  name: 'LiveChat',
  components: { SectionLiveChatInbox, UnreadMessage },
  data () {
    return {
      isMobile: false,
      showHeader: true,
      sound: require('@sounds/the-notification.mp3')
    }
  },
  computed: {
    ...mapGetters('liveChat', ['getLiveChatVisibility']),
    liveChatStatus () {
      return this.getLiveChatVisibility
    }
  },
  watch: {
    getLiveChatVisibility (newValue, oldValue) {
      if (newValue.visible) {
        this.getLiveChatVisibility.type === 'chatList' ? this.showHeader = true : this.showHeader = false
        setTimeout(() => {
          this.openDrawer()
        }, 60)
      }
    }
  },
  mounted () {
    this.isMobile = detectMobileScreen()
    this.initialPusher()
    this.fetchUnreadMessage({ role: 'STUDENT' })
  },
  methods: {
    ...mapActions('liveChat', ['showLiveChat', 'updateChatListUnreadMessage', 'updateChatListUnreadMessage',
      'storeLoading', 'storeUnreadMessage', 'fetchUnreadMessage', 'updateStreamMessage', 'storeChatStatus']),
    openDrawer () {
      this.$refs.drawerComponent.$data.sidebarOpen = true
    },
    closeDrawer () {
      this.$refs.drawerComponent.$data.sidebarOpen = false
    },
    changeState () {
      this.storeLoading(true)
      try {
        this.showLiveChat({
          chatStatus: {
            visible: true,
            type: 'chatList',
            chatUserName: this.$auth.user.chat_username
          },
          query: {
            role: 'STUDENT'
          }
        })
      } catch (e) {
      }
    },
    initialPusher () {
      if (this.$auth?.user) {
        const token = this.$auth.strategy.token.get() || ''
        const env = this.$config
        const pusher = PusherModule(token, env)
        const channel2 = pusher.subscribe('private-chat.student.' + (this.$auth.user.chat_username))
        channel2.bind('App\\Jobs\\BroadcastChatMessageSent', (data) => {
          this.playSound()
          this.storeUnreadMessage(data.total_unread)
          this.updateChatListUnreadMessage(data.chat)
          this.updateStreamMessage(data)
        })
        const channel3 = pusher.subscribe('private-chat.student.' + (this.$auth.user.chat_username))
        channel3.bind('App\\Jobs\\BroadcastChatMessageRead', (data) => {
          this.storeUnreadMessage(data.total_unread)
        })
        pusher.subscribe('presence-' + this.$auth.user.pusher.presence_channels.student)
      }
    },
    playSound () {
      const audio = this.$refs.correctSound
      audio.currentTime = 0
      audio.play()
    },
    sidebarClosed () {
      this.storeChatStatus({
        visible: false,
        type: '',
        chatUserName: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
.live-chat-header {
  @apply cursor-pointer relative mb-4 lg:mb-8 rounded-b-2xl shadow-banner lg:shadow-none py-5 px-4 lg:p-0;
  .title {
    @apply text__headline-4-xs--semibold lg:text__body-2-md--semibold ml-3 text-center lg:text-left lg:ml-9;
  }
}

</style>
