<template>
  <div class="expansion-panel" :class="{'expansion-panel--no-shadow': flat}" @click="toggleExpand">
    <div class="expansion-panel__header">
      <h3 v-if="!isHtml" class="expansion-title" :class="headerClasses">
        {{ header }}
      </h3>
      <client-only v-else>
        <h3
          class="expansion-title"
          :class="[headerClasses, {'expansion-title--primary': flat}]"
          v-html="header"
        />
      </client-only>
      <BaseIcon
        size="md-xl"
        mode="round"
        name="keyboard_arrow_down"
        :classes="chevronClasses"
      />
    </div>
    <p
      v-if="!isHtml"
      class="expansion-panel__content"
      :class="contentClasses"
    >
      {{ content }}
    </p>
    <client-only v-else>
      <p
        class="expansion-panel__content content__text"
        :class="contentClasses"
        v-html="content"
      />
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'BaseExpansionPanel',
  props: {
    header: {
      type: String,
      default: 'Please set header for expansion'
    },
    content: {
      type: String,
      default: 'please set content for expansion'
    },
    forceExpand: {
      type: Boolean,
      default: false
    },
    isHtml: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    handleType: {
      type: String,
      default: 'auto',
      valid: ['auto', 'manuel']
    }
  },
  data () {
    return {
      isExpand: this.status
    }
  },
  computed: {
    headerClasses () {
      return this.isExpand ? 'text-primary' : 'text-body-1'
    },
    chevronClasses () {
      let out = (this.isExpand ? 'rotate-180' : 'rotate-0') + ' lg:rounded-2xl rounded-lg ' +
          'transition-all duration-500 '
      if (!this.flat) {
        out = out + 'bg-gradient-3 '
      }
      return out
    },
    contentClasses () {
      return this.isExpand ? 'max-h-[1000px] pt-4' : 'max-h-0 pt-0'
    }
  },
  watch: {
    forceExpand (val) {
      if (this.handleType === 'manuel') {
        this.isExpand = val
      }
    }
  },
  methods: {
    toggleExpand () {
      if (this.handleType === 'manuel') {
        this.$emit('toggleExpand', !this.isExpand)
      } else {
        this.isExpand = !this.isExpand
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion-panel {
  @apply container shadow-banner lg:px-6 lg:py-4 p-4 rounded-2xl cursor-pointer;
  &--no-shadow{
    @apply shadow-none py-0 p-0;
  }
  &__header {
    @apply flex justify-between items-center space-x-2;
  }
  &__content {
    @apply text-body-2 lg:text__body-1-md--regular text__body-3-md--regular
    overflow-hidden transition-all duration-500;
  }
}
.expansion-title {
  @apply lg:text__headline-5-md--semibold text__headline-4-xs--semibold transition-all duration-500;
  &--primary {
    @apply text-primary;
  }
}
</style>
