import { render, staticRenderFns } from "./conversation-show-more.vue?vue&type=template&id=61bf44fa&scoped=true&"
import script from "./conversation-show-more.vue?vue&type=script&lang=js&"
export * from "./conversation-show-more.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bf44fa",
  null
  
)

export default component.exports