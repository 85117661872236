<template>
  <nav class="navigations">
    <BaseLink
      v-for="(item, i) in navigationList"
      v-if="item.meta.show"
      :key="i"
      class="navigations__item"
      :class="{'active': item.href === $router.currentRoute.fullPath}"
      :url="item.href"
    >
      {{ item.name }}
    </BaseLink>
  </nav>
</template>

<script>
export default {
  name: 'HeaderDesktopNavigation',
  inject: {
    navigationList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.navigations {
  @apply flex  text__body-2-md--regular text-label-text justify-center rounded-lg space-x-6;
  &__item {
    @apply hover:text-title cursor-pointer;
  }
}
</style>
