<script>
export default {
  name: 'BaseSeparator',
  props: {
    classes: {
      type: String,
      default: 'w-full border-t-[1px] border-primary-100'
    }
  }
}
</script>

<template>
  <hr class="h-[1px]" :class="classes">
</template>

<style scoped lang="scss">

</style>
