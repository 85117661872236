<template>
  <div class="space-y-6">
    <component
      :is="item.href ? 'a' : 'span'"
      v-for="(item, i) in navigationList"
      :key="i"
      class="flex items-center space-x-2 cursor-pointer"
      :href="item.href"
    >
      <BaseIcon
        v-if="item.meta && item.meta.type === 'custom'"
        custom-size="w-6 h-6"
        type="custom"
        gradient="gradient-1"
        :name="item.icon"
      />
      <BaseIcon
        v-else
        :name="item.icon"
        gradient="gradient-1"
        size="md"
      />
      <span class="text__headline-4-xs--regular  text-body-1">{{ item.name }}</span>
    </component>
  </div>
</template>

<script>
export default {
  name: 'SidebarNavigations',
  props: {
    navigationList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
