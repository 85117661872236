<template>
  <svg
    v-if="type === 'custom'"
    ref="icon"
    :fill="gradient ? 'url(#gradient1)' : 'currentColor'"
    :viewBox="viewBox"
    class="inline-flex"
    :class="[`${color} base-icon--${gradient}`,customSize]"
    v-html="getPath(name)"
  />
  <span
    v-else
    :class="[`material-icons-${mode}`,`base-icon--${color}`,`base-icon--${size}`,
             gradient ? `gradient--${gradient}` : '', classes]"
    v-on="$listeners"
  >
    {{ name }}
  </span>
</template>

<script>
import 'material-icons/iconfont/material-icons.css'
import customIcons from './custom-icons'
export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      default: 'user'
    },
    color: {
      type: String,
      default: 'primary'
    },
    mode: {
      type: String,
      default: 'round',
      validate: ['round', 'outlined', 'sharp']
    },
    size: {
      type: String,
      default: 'md',
      validate: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl',
        'xs-sm', 'sm-md', 'xs-md', 'md-xl', 'big', 'rating-star']
    },
    gradient: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'material',
      valid: ['material', 'custom']
    },
    customSize: {
      type: String,
      default: ''
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    scale: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.scale) {
        const icon = this.$refs.icon
        if (icon && icon.children[0]) {
          icon.children[0].setAttribute('style', `transform : scale(${this.scale})`)
        }
      }
    })
  },
  methods: {
    getPath (name) {
      return customIcons[name]
    }
  }
}
</script>

<style scoped lang="scss">
@import "icon-mixins";
$colors: primary,
teal,
orange,
pink,
primary-200,
primary-600,
purple,
success,
error,
warning,
label-text,
primary-700,
icon-text-field,
icon-text-field-hover,
color-icon,
star,
current,
green,
white;
$sizes:
2xs 12px 12px,
xs 16px 16px,
sm 20px 20px,
md 24px 24px,
lg 32px 32px,
xl 48px 48px,
2xl 56px 56px,
3xl 72px 72px,
4xl 96px 96px,
5xl 100px 100px,
6xl 128px 128px,
big 72px 100px,
xs-sm 16px 20px,
sm-md 20px 24px,
xs-md 16px 24px,
xs-sm 16px 20px,
xxs-xs 12px 16px,
md-lg 24px 32px,
md-xl 24px 48px,
xs-lg 16px 32px,
xl-2xl 48px 56px,
lg-xl 32px 48px,
lg-2xl 32px 56px,
xxxl-4xl 72px 96px,
xxxl-6xl 72px 128px,
rating-star 40px 56px;
@include color($colors);
@include hover($colors);
@include size($sizes);
@include gradient($gradients);
</style>
