import Vue from 'vue'
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, confirmed, min, max, max_value, min_value, double } from 'vee-validate/dist/rules'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('max_value', max_value)
extend('min_value', min_value)
extend('min', min)
extend('max', max)
extend('double', double)
localize({
  en: {
    messages: {
      required: 'This field is required',
      min_value: 'Must be higher than {min}',
      max_value: 'Must be lower than {max}',
      min: 'More than {length} characters',
      max: 'The field must be less than {length} characters',
      confirmed: 'Password and confirm are not the same'
    }
  }
})
