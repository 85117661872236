import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _764e854c = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _17204f32 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _92bf316c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _9a0957da = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _28f8638a = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _41e96ced = () => interopDefault(import('../pages/UIKITS.vue' /* webpackChunkName: "pages/UIKITS" */))
const _96d6ba74 = () => interopDefault(import('../pages/world.vue' /* webpackChunkName: "pages/world" */))
const _4273b8e4 = () => interopDefault(import('../pages/tutor-request/course.vue' /* webpackChunkName: "pages/tutor-request/course" */))
const _82bd10c8 = () => interopDefault(import('../pages/tutor-request/calendar/_id.vue' /* webpackChunkName: "pages/tutor-request/calendar/_id" */))
const _085e74f0 = () => interopDefault(import('../pages/tutor-request/confirm/_id.vue' /* webpackChunkName: "pages/tutor-request/confirm/_id" */))
const _ff40c778 = () => interopDefault(import('../pages/tutor-request/description/_id.vue' /* webpackChunkName: "pages/tutor-request/description/_id" */))
const _2606a29e = () => interopDefault(import('../pages/tutor-request/finish/_id.vue' /* webpackChunkName: "pages/tutor-request/finish/_id" */))
const _2908e8a2 = () => interopDefault(import('../pages/tutor-request/form/_id.vue' /* webpackChunkName: "pages/tutor-request/form/_id" */))
const _6d1cc2ba = () => interopDefault(import('../pages/tutor-request/languages/_id.vue' /* webpackChunkName: "pages/tutor-request/languages/_id" */))
const _5ff7d451 = () => interopDefault(import('../pages/tutor-request/price/_id.vue' /* webpackChunkName: "pages/tutor-request/price/_id" */))
const _0cfbdbd6 = () => interopDefault(import('../pages/tutor-request/tracking/_id.vue' /* webpackChunkName: "pages/tutor-request/tracking/_id" */))
const _c32a8bf6 = () => interopDefault(import('../pages/tutor/_name.vue' /* webpackChunkName: "pages/tutor/_name" */))
const _52a3a6c2 = () => interopDefault(import('../dynamic-pages/tutor-list.vue' /* webpackChunkName: "" */))
const _41861006 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3481f8d2 = () => interopDefault(import('../../config/pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _6933eaf6 = () => interopDefault(import('../../config/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6a04b319 = () => interopDefault(import('../../config/pages/auth/login-by-oauth.vue' /* webpackChunkName: "pages/auth/login-by-oauth" */))
const _232762c4 = () => interopDefault(import('../../config/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _6ce68dba = () => interopDefault(import('../../config/pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _249599a8 = () => interopDefault(import('../../config/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _62d89be6 = () => interopDefault(import('../../config/pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _764e854c,
    name: "about-us___en"
  }, {
    path: "/en/contact-us",
    component: _17204f32,
    name: "contact-us___en"
  }, {
    path: "/en/faq",
    component: _92bf316c,
    name: "faq___en"
  }, {
    path: "/en/privacy-policy",
    component: _9a0957da,
    name: "privacy-policy___en"
  }, {
    path: "/en/terms",
    component: _28f8638a,
    name: "terms___en"
  }, {
    path: "/en/UIKITS",
    component: _41e96ced,
    name: "UIKITS___en"
  }, {
    path: "/en/world",
    component: _96d6ba74,
    name: "world___en"
  }, {
    path: "/en/tutor-request/course",
    component: _4273b8e4,
    name: "tutor-request-course___en"
  }, {
    path: "/en/tutor-request/calendar/:id?",
    component: _82bd10c8,
    name: "tutor-request-calendar-id___en"
  }, {
    path: "/en/tutor-request/confirm/:id?",
    component: _085e74f0,
    name: "tutor-request-confirm-id___en"
  }, {
    path: "/en/tutor-request/description/:id?",
    component: _ff40c778,
    name: "tutor-request-description-id___en"
  }, {
    path: "/en/tutor-request/finish/:id?",
    component: _2606a29e,
    name: "tutor-request-finish-id___en"
  }, {
    path: "/en/tutor-request/form/:id?",
    component: _2908e8a2,
    name: "tutor-request-form-id___en"
  }, {
    path: "/en/tutor-request/languages/:id?",
    component: _6d1cc2ba,
    name: "tutor-request-languages-id___en"
  }, {
    path: "/en/tutor-request/price/:id?",
    component: _5ff7d451,
    name: "tutor-request-price-id___en"
  }, {
    path: "/en/tutor-request/tracking/:id?",
    component: _0cfbdbd6,
    name: "tutor-request-tracking-id___en"
  }, {
    path: "/en/tutor/:name?",
    component: _c32a8bf6,
    name: "tutor-name___en"
  }, {
    path: "/en/tutor-list/:param1?/:param2?/:param3?/:param4?/:param5?",
    component: _52a3a6c2,
    name: "tutor-list___en"
  }, {
    path: "/",
    component: _41861006,
    name: "index"
  }, {
    path: "/en/auth/forget-password",
    component: _3481f8d2,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _6933eaf6,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-by-oauth",
    component: _6a04b319,
    name: "auth-login-by-oauth___en"
  }, {
    path: "/en/auth/logout",
    component: _232762c4,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/set-password",
    component: _6ce68dba,
    name: "auth-set-password___en"
  }, {
    path: "/en/auth/signup",
    component: _249599a8,
    name: "auth-signup___en"
  }, {
    path: "/en/auth/verify",
    component: _62d89be6,
    name: "auth-verify___en"
  }, {
    path: "/fr/auth/forget-password",
    component: _3481f8d2,
    name: "auth-forget-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _6933eaf6,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-by-oauth",
    component: _6a04b319,
    name: "auth-login-by-oauth___fr"
  }, {
    path: "/fr/auth/logout",
    component: _232762c4,
    name: "auth-logout___fr"
  }, {
    path: "/fr/auth/set-password",
    component: _6ce68dba,
    name: "auth-set-password___fr"
  }, {
    path: "/fr/auth/signup",
    component: _249599a8,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/verify",
    component: _62d89be6,
    name: "auth-verify___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
