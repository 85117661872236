<template>
  <a
    v-if="!user"
    class="guest"
    :href="link"
    rel="nofollow"
  >
    <BaseIcon name="login" color="primary" size="xs-md" />
    <BaseButton flat size="xs-lg" :label="$t('common.actions.login')" />
  </a>
</template>

<script>
export default {
  name: 'HeaderNavGuest',
  data () {
    return {
      link: '/' + this.$i18n.locale + this.$routes.login.path + '?redirect_url=' + this.$config.baseUrl + this.$router.currentRoute.fullPath
    }
  },
  computed: {
    user () {
      return this.$auth?.user?.username || this.$auth?.user?.email
    }
  },
  watch: {
    '$route' (val) {
      this.link = '/' + this.$i18n.locale + this.$routes.login.path + '?redirect_url=' + this.$config.baseUrl + val.fullPath
    }
  }
}
</script>

<style lang="scss" scoped>
.guest {
  @apply flex items-center justify-end space-x-2 lg:space-x-2 cursor-pointer;
  &__label {
    @apply text-primary text__body-1-md--semibold;
  }
}
</style>
