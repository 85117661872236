<template>
  <div class="error">
    <div class="text2">
      <div v-if="showConfig">
        <div
          v-if="error.config"
          class="flex flex-col"
        >
          <span>please check this url</span>
          <span>{{ error.config.baseURL }} {{ error.config.url }}</span>
          <BaseButton
            @click="copy(`${error.config.baseURL}${error.config.url}`)"
          >
            copy url
            <AppIcon
              color="text-white"
              name="information"
            />
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      v-if="error.statusCode === 404"
      class="status-404"
    >
      <picture>
        <source srcset="@images/common/404-lg.png" media="(min-width: 1024px)">
        <img
          data-src="@images/common/404.png"
          class="lazyload w-full"
          alt=""
        >
      </picture>
      <p class="status-404__title">
        {{ $t("error_page.404.text.title") }}
      </p>
      <p class="status-404__description">
        {{ $t("error_page.404.text.description") }}
      </p>

      <div class="flex mt-6">
        <BaseButton
          :label="$t('error_page.home_page')"
          outline
          shadow
          size="sm-md"
          class="mr-2 w-[106px] lg:w-[168px] lg:mr-4"
          tag="a"
          :href="$routes.home.path"
        />
        <BaseButton
          :label="$t('error_page.online_english_tutor')"
          color="primary"
          shadow
          mode="fill"
          size="sm-md"
          tag="a"
          :href="$links.absolute($nuxt, 'tutor_list_online_english')"
        />
      </div>
    </div>
    <div
      v-if="error.statusCode === 401"
      class="error-401"
    >
      <div class="error__logo">
        <img
          data-src="/image/logo.png"
          alt="logo_home_banner"
          width="103"
          height="36"
          class="lazyload"
        >
      </div>
      <div class="error__image">
        <img
          data-src="@images/common/error-blue-board.svg"
          alt="logo_home_banner"
          width="500"
          height="311"
          class="lazyload"
        >
        <div class="error__image__content-wrapper">
          <span class="error__image__content-wrapper__error-code">{{ $t("error_page.401.code") }}</span>
          <div class="error__image__content-wrapper__error-texts">
            <span class="text1">{{ $t("error_page.401.text.1") }}</span>
            <span class="text2">{{ $t("error_page.401.text.2") }}</span>
            <span
              v-if="error.message"
              class="text2"
            > {{ error.message }}</span>
          </div>
        </div>
      </div>
      <div class="error__button">
        <BaseButton
          :label="$t('forms.actions.join_app')"
          color="primary"
          mode="fill"
          size="md"
          tag="a"
          :href="$routes.login.path"
        />
      </div>
    </div>
    <div
      v-if="error.statusCode === 500"
      class="error-500"
    >
      <div class="error__logo">
        <img
          data-src="/image/logo.png"
          alt="logo_home_banner"
          width="103"
          height="36"
          class="lazyload"
        >
      </div>
      <div class="error__image">
        <img
          data-src="@images/common/error-blue-board.svg"
          alt="logo_home_banner"
          width="500"
          height="311"
          class="lazyload"
        >
        <div class="error__image__content-wrapper">
          <span class="error__image__content-wrapper__error-code">{{ $t("error_page.500.code") }}</span>
          <div class="error__image__content-wrapper__error-texts">
            <span class="text1"> {{ $t("error_page.500.text.1") }}</span>
            <span
              v-if="error.message"
              class="text2"
            > {{ error.message }}</span>
          </div>
        </div>
      </div>
      <div class="error__button">
        <BaseButton
          :label="$t('error_page.button')"
          color="primary"
          mode="fill"
          size="md"
          tag="a"
          :href="$routes.home.path"
        />
      </div>
    </div>
    <div
      v-if="error.statusCode === 503"
      class="error-503"
    >
      <div class="error__logo">
        <img
          data-src="/image/logo.png"
          alt="logo_home_banner"
          width="103"
          height="36"
          class="lazyload"
        >
      </div>
      <div class="error__image">
        <img
          data-src="@images/common/error-blue-board.svg"
          alt="logo_home_banner"
          width="500"
          height="311"
          class="lazyload"
        >
        <div class="error__image__content-wrapper">
          <span class="error__image__content-wrapper__error-code">{{ $t("error_page.503.code") }}</span>
          <div class="error__image__content-wrapper__error-texts">
            <span class="text1"> {{ $t("error_page.503.text.1") }}</span>
          </div>
        </div>
      </div>
      <div class="error__button">
        <BaseButton
          :label="$t('error_page.button')"
          color="primary"
          mode="fill"
          size="md"
          tag="a"
          :href="$routes.home.path"
        />
      </div>
    </div>
    <div
      v-if="![404, 401, 500, 503].includes(error.statusCode)"
      class="error-500"
    >
      <div class="error__logo">
        <img
          data-src="/image/logo.png"
          alt="logo_home_banner"
          width="103"
          height="36"
          class="lazyload"
        >
      </div>
      <div class="error__image">
        <img
          data-src="@images/common/error-blue-board.svg"
          alt="logo_home_banner"
          width="500"
          height="311"
          class="lazyload"
        >
        <div class="error__image__content-wrapper">
          <span class="error__image__content-wrapper__error-code">{{ error.statusCode }}</span>
          <div class="error__image__content-wrapper__error-texts">
            <span class="text1">{{ error.message || $t("error_page.unknown_warning") }}</span>
          </div>
        </div>
      </div>
      <div class="error__button">
        <BaseButton
          :label="$t('error_page.button')"
          color="primary"
          mode="fill"
          size="md"
          tag="a"
          :href="$routes.home.path"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ErrorLayout',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showConfig () {
      return this.$config.mode === 'development'
    }
  },
  mounted () {
    if (this.error && this.error.statusCode === 404) {
      this.$sentry.captureMessage(this.error.message, this.error)
    }
  },
  methods: {
    copy (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.global.app_info({
          message: 'کپی انجام شد',
          position: 'topCenter',
          transitionIn: 'fadeInDown',
          transitionOut: 'fadeOutUp'
        })
      }).catch((e) => {
        this.$toast.global.app_info({
          message: this.$t('copy_in_clipboard_error')
        })
        this.$logger.captureException({ error: e })
      })
    }
  }
}
</script>

<style lang="scss">
.error {
  > [class*="error-"] {
    @apply flex flex-col items-center px-4 md:px-0 py-24 md:py-52;
  }

  &__image {
    @apply relative mt-6 mb-12;
    &__content-wrapper {
      @apply flex flex-col text-center justify-center text-white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &__error {
        &-code {
          font-size: 26vw;
          line-height: 0.8;
          @apply font-bold;
          @screen md {
            @apply text-7xl;
            line-height: 0.8;
          }
        }

        &-texts {
          @apply flex flex-col lg:text__body-1-md--regular space-y-1;
          .text2 {
            @apply text__body-3-md--regular;
          }
        }
      }
    }
  }
}
.status-404{
@apply flex flex-col mt-6 lg:mt-8 items-center px-4 text-center;
  &__title{
    @apply mt-4 text-primary-600 text__headline-3-xs--bold lg:text__headline-3-md--bold lg:mt-6;
  }
  &__description{
    @apply text__body-1-xs--regular lg:text__headline-5-md--regular text-body-1 mt-4 lg:mt-6;
  }
}
</style>
