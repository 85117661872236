<template>
  <div class="auth-dialog-header">
    <div class="text-center">
      <BaseIcon
        class="cursor-pointer float-left"
        name="clear"
        size="md"
        color="label-text"
        @click="$emit('hide')"
      />
      <p class="lg-hidden-block">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthDialogHeaderXs',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.auth-dialog-header {
  @apply p-4 lg:p-8 lg:pb-0  shadow-banner mb-8 rounded-b-2xl lg:shadow-none lg:rounded-b-none lg:mb-0;
}
</style>
