<template>
  <div v-if="!$auth.loggedIn" class="header-desktop__guest">
    <HeaderNavGuest @guestNavClicked="$emit('guestNavClicked')" />
  </div>
  <div v-else class="header-desktop__user">
    <HeaderNavUserClient
      :on-desktop="onDesktop"
      @userNavClicked="$emit('userNavClicked', $event)"
    />
  </div>
</template>

<script>
import HeaderNavGuest from '@design-system/section/header/header-shared/header-nav-user-guest.vue'
import HeaderNavUserClient from '@design-system/section/header/header-shared/header-nav-user-client.vue'
export default {
  name: 'HeaderNavBoth',
  components: {
    HeaderNavGuest,
    HeaderNavUserClient
  },
  props: {
    onDesktop: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
