export function getTeacherList (state) {
  return state.teacherList
}
export function getSelectedTeacher (state) {
  return state.selectedTeacher
}
export function getTeacherCalendar (state) {
  return state.teacherCalendar
}
export function getResumeItems (state) {
  return state.resumeItems
}
export function getCourses (state) {
  return state.courses
}
export function getCourseGroups (state) {
  return state.courseGroups
}
export function getFeedbacks (state) {
  return state.feedbacks
}
export function getTutorSelect (state) {
  return state.tutorSelect
}
