<template>
  <div class="flex items-center" :class="{'space-x-2': title}">
    <div class="check-box">
      <label :for="id" class="check-box__label" />
      <div class="check-box__wrapper" :class="{'check-box__wrapper--light': light}">
        <input
          :id="id"
          v-model="isChecked"
          :value="value"
          type="checkbox"
          :name="name"
          class="hidden"
        >
        <div class="check-box__wrapper--checked hidden">
          <svg
            class=""
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="checkmark__check"
              d="M3.88867 10.8755L8.18646 15.1777C8.36375 15.3488 8.60051 15.4444 8.8469 15.4444C9.09328 15.4444 9.33004 15.3488 9.50734 15.1777L18.1109 6.55556"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="title || Object.keys($slots).length > 0" class="flex-1">
      <slot>{{ title }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false
    },
    value: {
      type: [Array, String, Number],
      default: null
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    onChange (e) {
      this.$emit('input', this.isChecked)
    }
  }
}
</script>
<style scoped lang="scss">
.check-box {
  @apply relative w-6 h-6;
  &__label {
    @apply absolute w-full h-full z-10 cursor-pointer;
  }
  &__wrapper {
    @apply border-2 border-label-text w-full h-full rounded-lg flex items-center justify-center;
    &--light {
      @apply border border-icon-text-field;
    }
    &--checked {
      @apply w-[18px] h-[18px] bg-primary rounded-md items-center justify-center;
    }
  }

}
input:checked + .check-box__wrapper--checked {
  @apply flex;
}
//svg animation
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.2s cubic-bezier(0.65, 0, 0.45, 1) 0.1s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
</style>
