<template>
  <div
    v-if="loading"
    class="loading-page"
  >
    <div class="lds-ellipsis">
      <div /><div /><div /><div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
  data: () => ({
    loading: false
  }),
  methods: {
    start () {
      this.loading = true
    },
    finish () {
      setTimeout(() => {
        this.loading = false
      }, 10)
    }
  }
}
</script>

<style>
  .loading-page {
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999999;
    @apply flex-col fixed inset-0 justify-center flex items-center text-center w-full h-full;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    top: 33px;
    width: 13px;
    height: 13px;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    @apply bg-primary rounded-full absolute;
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

</style>
