<template>
  <div class="navigations">
    <ul class="grid grid-cols-2 lg:grid-cols-3 pl-4">
      <li v-for="(item, i) in navigationList" :key="i" class="list-disc text-white">
        <BaseLink
          class="navigations__navigations"
          :url="item.link"
        >
          {{ item.anchor }}
        </BaseLink>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FooterNavigations',
  props: {
    navigationList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    navigationClicked () {
      console.log('footer navigation clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.navigations {
  @apply space-y-8;
  &__navigations {
    @apply  text__headline-5-xs--regular lg:text__body-1-md--semibold
    cursor-pointer mb-4 whitespace-nowrap block;
  }
}
</style>
