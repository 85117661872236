<template>
  <component
    :is="isNuxtLink(url) ? 'nuxt-link' : 'a'"
    :href="url"
    :to="isNuxtLink(url) ? url : null"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  methods: {
    isNuxtLink (url) {
      if (!url) {
        return false
      }
      return !url.includes('http')
    }
  }
}
</script>

<style scoped>

</style>
