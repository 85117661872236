<template>
  <div
    v-if="show"
    class="base-dialog"
    :class="['wd-'+ width, 'ht-'+ height, 'mht-' + mobileHeight]"
  >
    <client-only>
      <DialogClickBack
        :key="name"
        :state="[state]"
        :close-methods="[hide]"
      />
    </client-only>
    <transition name="fade">
      <div v-show="showBack" class="base-dialog__backdrop" @click="hide()" />
    </transition>
    <div class="base-dialog__inner">
      <component
        :is="transitionName"
        v-show="showInner"
      >
        <BaseCard class="card-dialog" padding="none" :class="{'full max-h-custom': isFull}">
          <div class="card-dialog__header">
            <DialogHeader
              v-if="showDefaultHeader"
              :title-header="titleHeader"
              :header-type="mobileHeight !== 'full' ? 'line' : 'close'"
              @hide="close"
            >
              <template>
                <slot name="header-custom" />
              </template>
            </DialogHeader>
            <slot name="header" />
          </div>
          <div class="card-dialog__body scroll-custom">
            <slot name="body" />
          </div>
          <div class="card-dialog__footer">
            <slot name="footer" />
          </div>
        </BaseCard>
      </component>
    </div>
  </div>
</template>

<script>
import { ZoomCenterTransition, FadeTransition, SlideYDownTransition } from '@helper/transitions'
import { mapGetters, mapActions } from 'vuex'
import DialogClickBack from './dialog-click-back'
import DialogHeader from './dialog-header'
export default {
  name: 'BaseDialog',
  components: {
    DialogHeader,
    ZoomCenterTransition,
    SlideYDownTransition,
    FadeTransition,
    DialogClickBack
  },
  props: {
    name: {
      type: String,
      default: null
    },
    transition: {
      type: String,
      default: 'ZoomCenter',
      validate: ['ZoomCenter', 'Fade', 'SlideYDown']
    },
    width: {
      type: String,
      default: 'md',
      valid: ['auto', 'xs', 'sm', 'md', 'lg', 'full']
    },
    height: {
      type: String,
      default: 'auto',
      valid: ['auto', 'full', 'fixed', 'fixed-full']
    },
    mobileHeight: {
      type: String,
      default: 'default',
      valid: ['auto', 'full', 'default']
    },
    showDefaultHeader: {
      type: Boolean,
      default: true
    },
    titleHeader: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showInner: false,
      showBack: true,
      state: false,
      classArray: [
        'w-full',
        'h-full',
        'overflow-hidden',
        'lg:pr-4'
      ]
    }
  },
  computed: {
    ...mapGetters('dialog', ['getDialog']),
    show () {
      return this.getDialog === this.name
    },
    transitionName () {
      if (window.innerWidth < 760) {
        return 'SlideYDown' + 'Transition'
      }
      return this.transition + 'Transition'
    },
    isFull () {
      return this.mobileHeight === 'full' || this.height === 'full'
    }
  },
  watch: {
    getDialog (val) {
      if (val) {
        this.state = false
        this.fixedBody()
        setTimeout(() => {
          this.showBack = true
        }, 50)
        setTimeout(() => {
          this.showInner = true
          this.showBack = true
          this.state = true
        }, 60)
      }
    }
  },
  beforeDestroy () {
    this.state = false
    this.showInner = false
    this.showBack = false
    this.releaseBody()
  },
  methods: {
    ...mapActions('dialog', ['hideDialog']),
    hide () {
      this.state = false
      this.showInner = false
      this.showBack = false
      setTimeout(() => {
        this.hideDialog()
        this.releaseBody()
      }, 300)
    },
    close () {
      this.$emit('close')
      this.hide()
    },
    fixedBody () {
      for (const i in this.classArray) {
        document.body.classList.add(this.classArray[i])
      }
    },
    releaseBody () {
      for (const i in this.classArray) {
        document.body.classList.remove(this.classArray[i])
      }
    }
  }
}
</script>

<style lang="scss">
.base-dialog {
  height: -moz-available;
  height: -webkit-stretch;
  height: stretch;
  @apply z-50 fixed max-w-[100vw] max-h-[100vh] top-0 bottom-0 left-0 right-0 flex
  justify-center items-center;
  &__backdrop {
    @apply fixed top-0 bottom-0 left-0 right-0 z-[-1] bg-black opacity-50;
  }
  &__inner {
    @apply lg:flex lg:justify-center lg:items-center lg:w-auto  lg:bottom-auto lg:h-auto
    w-full absolute bottom-0;
  }
  .card-dialog {
    @apply flex flex-col lg:w-auto w-full lg:h-auto h-full lg:rounded-b-2xl rounded-b-none;
    &.full {
      @apply rounded-none lg:rounded-2xl lg:pb-3;
    }
    &__body {
      @apply w-full p-4 lg:p-8 pt-0 lg:pt-0 overflow-x-hidden;
    }
    &__header {
      @apply text-body-1;
      &--line {
        @apply flex space-x-2 w-[62px] h-[2px] bg-border-text-field mx-auto rounded-sm;
      }
      &--title {
        @apply lg:text__body-2-md--semibold;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.max-h-custom {
  @screen lg {
    max-height: 95vh;
  }
}
@import "dialog-sizes";
</style>
