<template>
  <div class="card-fix-bottom lg-hidden-block">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCardFixBottom'
}
</script>

<style scoped>
.card-fix-bottom {
  @apply fixed bottom-0 bg-white w-full z-30 p-4 inset-x-0;
}
</style>
