<template>
  <div
    v-if="isMounted"
    class="avatar-teacher"
    :class="[`avatar-teacher--text-${textMode}`]"
  >
    <div class="avatar-teacher__img">
      <nuxt-img
        v-if="imgSource(imgUrl)"
        :key="id"
        class="max-w-none rounded-full border-[0.5px] border-border-cart"
        :src="imgSource(imgUrl)"
        :alt="alt || title"
        :width="width"
        :height="height"
        loading="lazy"
        format="webp"
        placeholder
      />
    </div>
    <div v-if="showText" class="avatar-teacher__text">
      <p class="avatar-teacher__title text-overflow-dots w-[140px]">
        {{ title }}
      </p>
      <p class="avatar-teacher__subtitle">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>
<script>
import { isString } from 'lodash'
import detectDeviceMixin from '@helper/detectDeviceMixin'

export default {
  name: 'BaseAvatarTeacherُ',
  mixins: [detectDeviceMixin],
  props: {
    imgUrl: {
      type: [String, Object],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    size: {
      type: Object,
      default: () => {
        return {
          lg: '76',
          xs: '128'
        }
      }
    },
    textMode: {
      type: String,
      default: 'right',
      valid: ['below', 'right']
    }
  },
  data () {
    return {
      isMounted: false
    }
  },
  computed: {
    sizes () {
      /*      const obj = {
        big: 'xs:96px sm:96px md:172px lg:172px',
        list: 'xs:76px sm:76px md:128px lg:128px',
        card: 'xs:87px sm:87px md:124px lg:124px',
        'start-teaching-top': '64px',
        'start-teaching-testimonial': '64px',
        'course-group': '38px'
      } */
      return `xs:${this.size?.xs} sm:${this.size?.xs} md:${this.size?.lg} lg:${this.size?.lg}`
    },
    width () {
      /*      const obj = {
        big: ['96', '172'],
        list: ['76', '128'],
        card: ['87', '124'],
        'start-teaching-top': ['64', '64'],
        'start-teaching-testimonial': ['64', '64'],
        'course-group': ['38', '38']
      } */
      return this.isMobile ? this.size?.xs : this.size?.lg
    },
    height () {
      /*      const obj = {
              big: ['96', '172'],
              list: ['76', '128'],
              card: ['87', '124'],
              'start-teaching-top': ['64', '64'],
              'start-teaching-testimonial': ['64', '64'],
              'course-group': ['38', '38']
            } */
      return this.isMobile ? this.size?.xs : this.size?.lg
    },
    showText () {
      // const modes = ['list', 'course-group', 'card', 'big']
      return this.title || this.subtitle
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    imgSource (img) {
      if (isString(img)) {
        return img
      }
      return img?.source?.jpg_128X128?.url || img?.jpg_128X128?.url ||
          img?.source?.jpg_80X80?.url || img?.jpg_80X80?.url
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-teacher {
  @apply flex items-center space-x-4;
  &__title {
    @apply text__caption-xs--bold lg:text__body-3-md--bold text-title;
  }
  &__subtitle {
    @apply text__caption-1-xs--regular lg:text__caption-xs--regular text-body-2;
  }
  &__text {
    @apply text-left;
  }
}
.avatar-teacher--text-below {
  @apply lg:flex-col lg:space-x-0;
  .avatar-teacher__text {
    @apply mt-0 lg:mt-4 text-left lg:text-center;
  }
  .avatar-teacher__title{
    @apply text__body-2-md--bold mt-1;
  }
  .avatar-teacher__subtitle{
    @apply  text__body-3-md--regular mt-1;
  }
}
</style>
