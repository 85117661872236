export default function () {
  return {
    courseGroups: [],
    importantCities: [],
    listQueries: {},
    listParams: {},
    filters: {},
    schemaRating: {},
    priceChart: {},
    listSorts: [],
    worldCountries: [],
    startTeaching: {},
    show_teachers_count: true,
    show_total: true,
    show_sorts: true,
    tutorListDesignColor: 'primary-600',
    listTitle: 'English teachers that match your needs'
  }
}
