<template>
  <div>
    <BaseButtonCustom
      v-if="app('google')"
      class="w-full mb-3 h-12 bg-external-google"
      :loading="loadings.google"
      @click="getLink('google')"
    >
      <span>
        <BaseIcon name="google" type="custom" color="fill-white" custom-size="w-6 h-6" />
        <span class="text-white">
          {{ $t('common.actions.login_by', {name: 'Google'}) }}
        </span>
      </span>
    </BaseButtonCustom>
    <BaseButtonCustom
      v-if="app('facebook')"
      class="w-full mb-3 h-12 bg-external-facebook"
      :loading="loadings.facebook"
      @click="getLink('facebook')"
    >
      <span>
        <BaseIcon name="facebook-full" type="custom" color="fill-white" custom-size="w-6 h-6" />
        <span class="text-white">
          {{ $t('common.actions.login_by', {name: 'Facebook'}) }}
        </span>
      </span>
    </BaseButtonCustom>
  </div>
</template>

<script>
import BaseButtonCustom from '@design-system/base/button/button-custom.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'BlockAuthLoginThirdParty',
  components: {
    BaseButtonCustom
  },
  data () {
    return {
      loadings: {
        google: false,
        facebook: false
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['getOauthProvider'])
  },
  methods: {
    ...mapActions('authentication', ['getOauthLink']),
    app (name) {
      return this.getOauthProvider.includes(name)
    },
    async getLink (provider) {
      this.loadings[provider] = true
      try {
        const data = await this.getOauthLink({ provider })
        if (data && data.url) {
          if (this.$route.query?.redirect_url) {
            this.$auth.$storage.setLocalStorage('oauth_redirect_url', this.$route.query.redirect_url)
          } else if (!this.$route.fullPath.includes('/auth') &&
              !this.$route.fullPath.includes('/tutor-list')) {
            this.$auth.$storage.setLocalStorage('oauth_redirect_url', this.$route.path)
          }
          window.location.href = data.url
        }
      } catch (e) {
      } finally {
        this.loadings[provider] = false
      }
    }
  }
}
</script>

<style scoped>

</style>
