import {
  STORE_EMAIL_REGISTRATION,
  STORE_OAUTH_PROVIDER, STORE_ACTION_AFTER_LOGIN, UPDATE_PASSWORD_TYPE
} from './auth-mutation-types'
export default {
  [STORE_OAUTH_PROVIDER] (state, payload) {
    state.oauthProvider = payload
  },
  [STORE_EMAIL_REGISTRATION] (state, payload) {
    state.emailRegistration = payload
  },

  [STORE_ACTION_AFTER_LOGIN] (state, payload) {
    state.actionAfterLogin = payload
  },
  [UPDATE_PASSWORD_TYPE] (state, payload) {
    state.newPasswordType = payload
  }
}
