export default function () {
  return {
    oauthProvider: [],
    origin: 'WEBSITE_LOGIN',
    origins: ['WEBSITE_LOGIN', 'TUTOR_REQUEST', 'START_TEACHING'],
    actionAfterLogin: {
      type: '',
      target: ''
    },
    newPasswordType: null
  }
}
