export default function ({ app, $auth, route }) {
  $auth.onRedirect((to) => {
    let nextPage = app.localePath(to)
    if (route.path !== app.localePath(app.$routes.login.path)) {
      nextPage = app.localePath({
        name: app.$routes.login.name,
        query: {
          redirect_url: route.fullPath
        }
      })
      return nextPage
    }
    return nextPage
  })
}
