import { urlParams } from '@helper/utils'
import { TUTOR_CALENDAR, TUTOR_COURSE_GROUP, TUTOR_FEEDBACK, TUTOR_REQUEST_SELECT_TUTOR } from '@endpoints'
import {
  STORE_TUTOR_CALENDAR,
  STORE_TUTOR_COURSE_GROUP,
  STORE_TUTOR_COURSES, STORE_TUTOR_FEEDBACKS,
  STORE_TUTOR_SELECTED,
  STORE_TUTOR_SELECT
} from '../mutation-types'

export async function fetchTutorCalendar ({ commit, state }, payload) {
  const response = await this.$api.get({
    url: urlParams(TUTOR_CALENDAR, { teacherId: payload.teacherId }),
    query: payload
  })
  const data = response?.data
  if (data) {
    commit(STORE_TUTOR_CALENDAR, data)
  }
}
export async function fetchTutorCourses ({ commit, state }, payload) {
  const response = await this.$api.get({
    url: urlParams(TUTOR_COURSE_GROUP.url, { teacherId: payload.teacherId }),
    query: payload,
    useCache: TUTOR_COURSE_GROUP.cache
  })
  const data = response?.data
  if (data) {
    commit(STORE_TUTOR_COURSES, data?.courses)
    commit(STORE_TUTOR_COURSE_GROUP, data?.course_groups)
  }
}
export async function fetchTutorFeedbacks ({ commit, state }, payload) {
  const response = await this.$api.get({
    url: urlParams(TUTOR_FEEDBACK.url, { teacherId: payload.teacherId }),
    query: payload,
    useCache: TUTOR_FEEDBACK.cache
  })
  const data = response?.data
  if (data) {
    data.teacherId = payload.teacherId
    commit(STORE_TUTOR_FEEDBACKS, data)
  }
}
export function updateSelectedTeacher ({ commit, state }, payload) {
  commit(STORE_TUTOR_SELECTED, payload)
}
export async function fetchTutorSelect ({ commit, state }, payload) {
  const response = await this.$api.post({
    url: TUTOR_REQUEST_SELECT_TUTOR.url,
    payload
  })
  const data = response?.data
  if (data) {
    commit(STORE_TUTOR_SELECT, data)
  }
}
