<template>
  <div class="message-date">
    <hr class="message-date__line">
    <div class="message-date__text">
      {{ text }}
    </div>
    <hr class="message-date__line">
  </div>
</template>

<script>
export default {
  name: 'ConversationMessageDate',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.message-date{
  @apply flex w-full items-center;
  &__line{
    @apply border w-full border-primary-100;
  }
  &__text{
    @apply text-label-text text__caption-xs--regular lg:text__body-3-md--regular  w-auto min-w-fit max-w-fit px-2 mx-1 lg:mx-2;
  }
}
</style>
