<template>
  <BaseDropdown :dropdown-position="dropdownPosition">
    <template #activator="{ toggleDropdown }">
      <div class="nav-user" :title="user" @click="toggleDropdown">
        <BaseIcon gradient="gradient-1" name="account_circle" size="md-lg" />
        <span v-if="onDesktop" class="nav-user__name max-w-[100px] text-overflow-dots">
          {{ user }}
        </span>
        <BaseIcon
          v-if="onDesktop"
          color="icon-text-field"
          classes="hover--icon-text-field-hover"
          name="expand_more"
          size="md"
        />
      </div>
    </template>
    <template #dropdown>
      <HeaderDropdownNavUser
        @userNavClicked="$emit('userNavClicked', $event)"
      />
    </template>
  </BaseDropdown>
</template>

<script>
import HeaderDropdownNavUser from '@design-system/section/header/header-shared/header-dropdown-nav-user.vue'

export default {
  name: 'HeaderNavUser',
  components: { HeaderDropdownNavUser },
  props: {
    onDesktop: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dropdownPosition () {
      return this.onDesktop ? '-bottom-5 translate-y-full right-0 w-full' : 'bottom-0 translate-y-full right-0'
    },
    user () {
      return this.$auth.user.username || this.$auth.user.email
    }
  }

}
</script>

<style lang="scss" scoped>
.nav-user {
  @apply flex  basis-1/4 justify-end items-center space-x-4 cursor-pointer;
  &__name {
    @apply text-primary text__body-2-md--regular text-body-2;
  }
}
</style>
