import { validationBackend } from '@helper/utils'
import { EMAIL_REGISTRATION_CONFIRMATION, OAUTH_LINK, OAUTH_PROVIDER, SET_PASSWORD } from '../endpoints'
import { STORE_ACTION_AFTER_LOGIN, STORE_OAUTH_PROVIDER } from './auth-mutation-types'
export async function fetchOauthProvider ({ commit }, payload) {
  try {
    const { data } = await this.$api.get({
      errorType: 'page',
      url: OAUTH_PROVIDER
    })
    commit(STORE_OAUTH_PROVIDER, data)
  } catch (e) {
  }
}
export async function getOauthLink ({ commit }, payload) {
  const { data } = await this.$api.get({
    url: OAUTH_LINK,
    query: payload
  })
  return data
}
export async function loginByOauth ({ commit, app, state }, payload) {
  try {
    payload.auth_origin = state.origin
    const { data } = await this.$auth.loginWith('loginByOauth', {
      data: { ...payload },
      errorType: 'page'
    })
    return data.data
  } catch (err) {
    validationBackend(err, (name, value) => {
      app.$toast.global.app_error({
        message: value
      })
    })
  }
}
export async function emailRegistration ({ commit, app, state }, payload) {
  try {
    payload.auth_origin = state.origin
    const response = await this.$api.post({
      url: EMAIL_REGISTRATION_CONFIRMATION,
      payload
    })
    if (response && response?.data) {
      return response.data
    }
  } catch (err) {
  }
}
export async function loginByVerifyCode ({ commit, app, state }, payload) {
  try {
    payload.auth_origin = state.origin
    const data = await this.$auth.loginWith('loginByVerifyCode', {
      data: { ...payload }
    })
    if (data?.response?.status) {
      validationBackend(data, (name, value) => {
        this.$toast.global.app_error({
          message: value
        })
      })
    } else {
      return data?.data?.data
    }
  } catch (err) {}
}
export async function loginByPassword ({ commit, app, state }, payload) {
  try {
    payload.auth_origin = state.origin
    const data = await this.$auth.loginWith('loginByPassword', {
      data: { ...payload }
    })
    if (data?.response?.status) {
      validationBackend(data, (name, value) => {
        this.$toast.global.app_error({
          message: value
        })
      })
    } else {
      return data?.data?.data
    }
    // return response?.data?.data
  } catch (err) {}
}
export async function setPassword ({ commit, app, state }, payload) {
  payload.auth_origin = state.origin
  const { data } = await this.$api.post({
    url: SET_PASSWORD,
    payload
  })
  return data
}
export async function loginTeacherByLink ({ commit, app, state }, payload) {
  try {
    const data = await this.$auth.loginWith('loginTeacherByLink', {
      data: { ...payload }
    })
    if (data?.response?.status) {
      validationBackend(data, (name, value) => {
        this.$toast.global.app_error({
          message: value
        })
      })
    } else {
      return data?.data?.data
    }
  } catch (err) {
    validationBackend(err, (name, value) => {
      app.$toast.global.app_error({
        message: value
      })
    })
  }
}

export function saveActionAfterLogin ({ commit, app, state }, payload) {
  try {
    commit(STORE_ACTION_AFTER_LOGIN, payload)
  } catch (err) {
    validationBackend(err, (name, value) => {
      app.$toast.global.app_error({
        message: value
      })
    })
  }
}
