<template>
  <div class="courses">
    <ul class="ml-4">
      <li class="list-disc text-white text__headline-5-xs--regular lg:text__body-1-md--semibold mb-2">
        {{ courses.anchor }}
      </li>
    </ul>
    <div class="grid grid-cols-2 lg:grid-cols-3 pl-4 lg:pl-8">
      <div v-for="(item, i) in courses.children" :key="i" class="text-white mb-4">
        <BaseLink :url="item.link" class="text__button-link-sm--regular underline lg:text__button-link-lg--regular">
          {{ item.anchor }}
        </BaseLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterCourses',
  props: {
    courses: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.courses {
  &__navigations {
    @apply  text__headline-5-xs--regular lg:text__headline-5-md--regular
    cursor-pointer mb-4 whitespace-nowrap block;
  }
}
</style>
