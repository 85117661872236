<template>
  <div class="expansion" :class="!isClosed ? activeClass : ''">
    <div
      class="expansion__action"
      :class="actionClass"
      @click="toggle"
    >
      <BaseIcon
        class="transition"
        name="keyboard_arrow_right"
        :color="isClosed ? 'icon-text-field' : 'primary'"
        :class="{'rotate-90': !isClosed}"
      />
      <slot name="action" :is-closed="isClosed" />
    </div>
    <div
      :class="height"
      class="expansion__content"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expansion',
  props: {
    heightDefault: {
      type: String,
      default: 'max-h-0'
    },
    activeClass: {
      type: String,
      default: ''
    },
    actionClass: {
      type: String,
      default: 'ml-4'
    },
    status: {
      type: String,
      default: 'close',
      valid: ['open', 'close']
    }
  },
  data () {
    return {
      height: 'max-h-0'
    }
  },
  computed: {
    isClosed () {
      return this.height === this.heightDefault
    },
    label () {
      return this.isClosed ? 'Show more' : 'Show less'
    }
  },
  watch: {
    status (val) {
      if (val === 'open') {
        this.height = 'max-h-[20000px]'
      } else {
        this.height = this.heightDefault
      }
    }
  },
  mounted () {
    if (this.status === 'open') {
      this.height = 'max-h-[20000px]'
    } else {
      this.height = this.heightDefault
    }
  },
  methods: {
    toggle () {
      this.$emit('toggle', this.isClosed)
      this.height = this.height === this.heightDefault ? 'max-h-[20000px]' : this.heightDefault
    }
  }

}
</script>

<style scoped lang="scss">
.expansion {
  &__content {
    @apply transition-all duration-500 overflow-hidden relative;
  }
  &__action {
    @apply flex items-center justify-start space-x-1 cursor-pointer;
  }
}
</style>
