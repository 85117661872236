<template>
  <div class="base-timer" :class="classes">
    <span>{{ time === 0 || time >= 10 ? time : '0'+time }}</span>
    <span>seconds</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BaseTimer',
  props: {
    totalTime: {
      type: Number,
      default: 59
    },
    classes: {
      type: String,
      default: 'bg-pink-200 text-pink'
    }
  },
  data () {
    return {
      time: 59,
      timer: null
    }
  },
  mounted () {
    this.time = JSON.parse(JSON.stringify(this.totalTime))
    this.startTimer()
  },
  methods: {
    ...mapActions('local', ['emailRegistration']),
    startTimer () {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
        } else {
          this.$emit('finished')
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.base-timer {
  @apply  rounded-2xl px-2 py-1 flex items-center space-x-2;
  span:first-of-type{
    @apply text__body-2-md--semibold;
  }
  span:last-of-type {
    @apply text__caption-1-xs--semibold;
  }
}
</style>
