<template>
  <div class="avatar-conversation" :class="['mode-' + mode]">
    <img
      v-if="imgUrl"
      :key="imgUrl"
      class="lazyload max-w-full"
      :data-src="imgUrl"
      :alt="name"
    >
    <div v-else class="avatar-conversation__placeholder">
      <BaseIcon
        :size=" mode === 'profile' ? 'md' : 'lg'"
        name="person"
        color="primary-200"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAvatarConversation',
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'conversation',
      valid: ['conversation', 'profile']
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-conversation {
  @apply flex items-center  rounded-full  p-0.5;
  &.mode-conversation {
    @apply w-[48px] h-[48px] lg:w-[64px] lg:h-[64px];
    img {
      @apply w-[44px] h-[44px] lg:w-[60px] lg:h-[60px];
    }
  }

  &.mode-profile {
    @apply w-[40px] h-[40px] lg:w-[48px] lg:h-[48px];
    img {
      @apply w-[36px] h-[36px] lg:w-[44px] lg:h-[44px];
    }
  }

  background: linear-gradient(242.69deg, #6DC1DC 16.13%, #A690FC 30.79%, #FC96BB 56.79%, #FFC397 84.3%);

  img {
    @apply rounded-full;
  }

  &__placeholder {
    @apply rounded-full  bg-white flex items-center justify-center w-full h-full;
  }
}
</style>
