<template>
  <BaseDialog
    :ref="name"
    width="sm"
    height="auth"
    mobile-height="full"
    :name="name"
    class="auth-dialog"
    :show-default-header="false"
  >
    <template #header>
      <AuthDialogHeaderXs :title="$t('common.titles.login')" @hide="hide" />
    </template>
    <template #body>
      <SectionAuthLogin wrapper="dialog" @hide="hide" @submitted="submitted" />
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionAuthLogin from '@design-system/section/auth/auth-login'
import ActionHelper from '@helper/action-after-login'
import AuthDialogHeaderXs from './auth-dialog-header-xs'
export default {
  name: 'SectionAuthDialog',
  components: {
    SectionAuthLogin,
    AuthDialogHeaderXs
  },
  mixins: [ActionHelper],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs']),
    ...mapGetters('authentication', ['getOauthProvider', 'getActionAfterLogin']),
    name () {
      return this.allDialogs.AUTH_LOGIN
    }
  },
  mounted () {
    if (this.getOauthProvider.length === 0) {
      this.fetchOauthProvider()
    }
  },
  methods: {
    ...mapActions('authentication', ['fetchOauthProvider']),
    hide () {
      this.$refs[this.name].hide()
    },
    async submitted () {
      await this.hide()
      this.action()
    }
  }
}
</script>

<style scoped>

</style>
