<template>
  <div class="mt-auto mb-16">
    <h4 class="mb-6 text__body-2-xs--regular">
      {{ $t('common.labels.social_media') }}:
    </h4>
    <div class="flex space-x-5 mb-4">
      <BaseIcon
        v-for="media in socialMedias"
        :key="media.src"
        :name="media.icon"
        type="custom"
        scale="2"
        color="text-primary"
        custom-size="w-[39px] h-[39px]"
        view-box="0 0 39 39"
        class="cursor-pointer"
        @click="socialMediaClicked(media.link)"
      />
    </div>
    <BaseListItem
      :label="email"
      name="email"
      color="primary"
      label-class="text__body-2-md--semibold"
    />
  </div>
</template>

<script>
export default {
  name: 'SocialMedias',
  data () {
    return {
      socialMedias: [
        {
          icon: 'linkedin',
          link: null
        },
        {
          icon: 'twitter',
          link: null
        },
        {
          icon: 'instagram',
          link: null
        },
        {
          icon: 'facebook',
          link: null
        }
      ]
    }
  },
  computed: {
    email () {
      return this.$config.app_media.email
    }
  },
  methods: {
    socialMediaClicked (link) {
      console.log('media clicked', link)
    }
  }
}
</script>

<style scoped></style>
