<template>
  <div class="base-card" :class="['padding-' + padding, {'base-card--xs-flat': xsFlat}]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    padding: {
      type: String,
      default: 'default',
      valid: ['default', 'dialog', 'none', 'xs-none']
    },
    xsFlat: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.base-card {
  @apply bg-white shadow-cart-resting-xs lg:shadow-cart-resting rounded-2xl;
  &--xs-flat {
    @apply shadow-none rounded-none lg:shadow-cart-resting lg:rounded-2xl;
  }
  &.padding-none {
    @apply lg:p-0 p-0;
  }
  &.padding-default {
    @apply p-4 lg:p-6;
  }
  &.padding-xs-none {
    @apply p-0 lg:p-6;
  }
  &.padding-dialog {
    @apply p-4 lg:p-8;
  }
}
</style>
