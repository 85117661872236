<template>
  <h2 class="base-section-title">
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'BaseTitleSection',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.base-section-title {
  @apply text-center text__headline-3-xs--bold lg:text__headline-3-md--bold;
}
</style>
