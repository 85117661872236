<template>
  <div>
    <SectionAuthDialogLogin />
    <SectionAuthDialogSignup />
    <SectionAuthDialogForgetPassword />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionAuthDialogLogin from '@design-system/section/auth/auth-dialog/auth-dialog-login'
import SectionAuthDialogSignup from '@design-system/section/auth/auth-dialog/auth-dialog-signup'
import SectionAuthDialogForgetPassword from '@design-system/section/auth/auth-dialog/auth-dialog-forget-password'

export default {
  name: 'SectionAuthDialogSetup',
  components: {
    SectionAuthDialogLogin,
    SectionAuthDialogSignup,
    SectionAuthDialogForgetPassword
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs'])
  },
  created () {
    this.$nuxt.$on('AuthDialogLogin', () => {
      this.showDialog(this.allDialogs.AUTH_LOGIN)
    })
    this.$nuxt.$on('AuthDialogSignup', () => {
      this.showDialog(this.allDialogs.AUTH_SIGNUP)
    })
    this.$nuxt.$on('AuthDialogForgetPassword', () => {
      this.showDialog(this.allDialogs.AUTH_FORGET_PASSWORD)
    })
  },
  methods: {
    ...mapActions('dialog', ['showDialog'])
  }
}
</script>

<style scoped>

</style>
