import { SEARCH_COURSES, SEARCH_COURSES_LIST } from '@endpoints'
import { STORE_COURSES, STORE_COURSES_PARENT, UPDATE_COURSES_LOADING_LIST } from '../mutation-types'
import {
  STORE_LANGUAGE_STATE,
  UPDATE_COURSE_ID,
  UPDATE_COURSES_TYPE,
  UPDATE_COURSES_LOADING, UPDATE_COURSES_SEARCH_TERM
}
from './mutation-types'
export async function searchCourses ({ commit, state }) {
  commit(UPDATE_COURSES_LOADING, true)
  const response = await this.$api.get({
    url: SEARCH_COURSES.url,
    query: {
      type: state.typeSelected,
      id: state.courseId,
      search: state.searchTerm
    },
    useCache: SEARCH_COURSES.cache
  })
  const data = response?.data
  if (data) {
    commit(UPDATE_COURSES_LOADING, false)
    commit(STORE_COURSES, data?.items)
    commit(STORE_COURSES_PARENT, data.parent)
    /*    if (data.type === 'COURSES') {
      commit(UPDATE_COURSES_TYPE, 'COURSES')
      commit(STORE_LANGUAGE_STATE, data.language)
    } else if (data.type === 'LANGUAGES') {
      commit(STORE_LANGUAGES_LIST, data.languages)
      commit(UPDATE_COURSES_TYPE, 'LANGUAGES')
    } else {
      commit(UPDATE_COURSES_TYPE, 'SEARCH')
      commit(STORE_LANGUAGES_LIST, data.languages)
    } */
    return data
  }
}
export async function searchCoursesList ({ commit, state }) {
  commit(UPDATE_COURSES_LOADING_LIST, true)
  const response = await this.$api.get({
    url: SEARCH_COURSES_LIST.url,
    query: {
      type: state.typeSelected,
      id: state.courseId,
      search_term: state.searchTerm
    },
    useCache: SEARCH_COURSES_LIST.cache
  })
  const data = response?.data
  if (data) {
    commit(UPDATE_COURSES_LOADING_LIST, false)
    commit(STORE_COURSES, data.course_groups)
    return commit(STORE_COURSES_PARENT, data.parent)
  }
}
export function updateType ({ commit }, payload) {
  return commit(UPDATE_COURSES_TYPE, payload)
}
export function updateCourseId ({ commit }, payload) {
  return commit(UPDATE_COURSE_ID, payload)
}
export function updateSearchTerm ({ commit }, payload) {
  return commit(UPDATE_COURSES_SEARCH_TERM, payload)
}
export function updateLanguage ({ commit }, payload) {
  return commit(STORE_LANGUAGE_STATE, payload)
}
