export function getCourseGroups (state) {
  return state.courseGroups
}
export function getImportantCities (state) {
  return state.importantCities
}
export function getSchemaRating (state) {
  return state.schemaRating
}
export function getPriceChart (state) {
  return state.priceChart
}
export function getListQueries (state) {
  return state.listQueries
}
export function getListParams (state) {
  return state.listParams
}
export function getFilters (state) {
  return state.filters
}
export function getListSorts (state) {
  return state.listSorts
}
export function getWorldCountries (state) {
  return state.worldCountries
}
export function getShowTeachersCount (state) {
  return state.show_teachers_count
}
export function getShowTotal (state) {
  return state.show_total
}
export function getShowSorts (state) {
  return state.show_sorts
}
export function getTutorListDesignColor (state) {
  if (state.tutorListDesignColor === 'DEFAULT') {
    return 'primary'
  }
  return state.tutorListDesignColor.toLowerCase()
}
export function getListTitle (state) {
  return state.listTitle
}
export function getStartTeaching (state) {
  return state.startTeaching
}
