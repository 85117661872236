<template>
  <component
    :is="tag"
    v-bind="attr"
    class="base-badge "
    :class="[{ selected},{disabled}, bgColor]"
    v-on="$listeners"
  >
    <baseIcon
      v-if="iconLeft"
      :name="iconLeft"
      size="xs"
      :color="iconColor"
      class="mr-1"
    />
    <slot>
      <span class="base-badge__text">
        {{ text }}
      </span>
    </slot>
    <baseIcon
      v-if="iconRight"
      :name="iconRight"
      size="xs"
      :color="iconColor"
      class="ml-1"
    />
  </component>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSpin: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'span'
    },
    to: {
      type: [String, Object],
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: 'bg-white'
    }
  },
  computed: {
    attr () {
      if (this.tag === 'nuxt-link') {
        return { to: this.to }
      }
      if (this.tag === 'a') {
        return { href: this.to, target: '_blank' }
      }
      return ''
    },
    iconColor () {
      return this.selected ? 'primary-700' : 'primary'
    }
  }
}
</script>

<style scoped lang="scss">
.base-badge {
  @apply text-primary border border-primary rounded-2xl
  px-4 py-2 text__button-link-sm--regular whitespace-nowrap;
  &:hover {
    @apply bg-primary-100;
    span {
      @apply text-primary-600 border-primary-600;
    }
  }
  &.selected {
    @apply bg-primary-200 text-primary-700 border-primary-700;
  }
  &.disabled {
    @apply pointer-events-none opacity-50;
  }
}
</style>
