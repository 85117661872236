import { render, staticRenderFns } from "./sidebar-content.vue?vue&type=template&id=8595ee1e&scoped=true&"
import script from "./sidebar-content.vue?vue&type=script&lang=js&"
export * from "./sidebar-content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8595ee1e",
  null
  
)

export default component.exports