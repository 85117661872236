import { STORE_COURSES_PARENT, UPDATE_COURSES_LOADING_LIST, STORE_COURSES } from '../mutation-types'
import {
  STORE_LANGUAGES_LIST,
  STORE_LANGUAGE_STATE,
  UPDATE_COURSES_LOADING,
  UPDATE_COURSES_SEARCH_TERM,
  UPDATE_COURSE_ID,
  UPDATE_COURSES_TYPE,
  UPDATE_COURSES_ACTIVE_RETURN
} from './mutation-types'
export default {
  [STORE_LANGUAGE_STATE] (state, payload) {
    state.languageState = payload
  },
  [STORE_LANGUAGES_LIST] (state, payload) {
    state.languagesList = payload
  },
  [UPDATE_COURSES_LOADING] (state, payload) {
    state.loadingList = payload
  },
  [UPDATE_COURSE_ID] (state, payload) {
    state.courseId = payload
  },
  [UPDATE_COURSES_TYPE] (state, payload) {
    state.typeSelected = payload
  },
  [UPDATE_COURSES_SEARCH_TERM] (state, payload) {
    state.searchTerm = payload
  },
  [UPDATE_COURSES_ACTIVE_RETURN] (state, payload) {
    state.activeReturn = payload
  },
  [STORE_COURSES_PARENT] (state, payload) {
    state.parent = payload
  },
  [UPDATE_COURSES_LOADING_LIST] (state, payload) {
    state.loadingList = payload
  },
  [STORE_COURSES] (state, payload) {
    state.courses = payload
  }
}
