<template>
  <div class="auth-verify-email" :class="{'auth-verify-email--dialog': wrapper === 'dialog'}">
    <div>
      <BaseTitleAuth
        :title="$t('common.titles.verify_email')"
        class="mb-6"
        :class="{'lg-block-hidden': wrapper === 'dialog'}"
      />
    </div>
    <div class="auth-verify-email__edit">
      <span class="max-w-[200px] text-overflow-dots text__caption-xs--semibold text-body-1">{{ email }}</span>
      <BaseButton
        v-if="wrapper === 'page'"
        outline
        size="sm"
        label="change"
        :to="forget ? $links.absolute($nuxt, 'forget_password') : $links.absolute($nuxt, 'signup')"
        tag="a"
        class="w-[125px]"
      />
      <BaseButton
        v-if="wrapper === 'dialog'"
        outline
        size="sm"
        label="change"
        class="w-[125px]"
        @click="$emit('signupClicked')"
      />
    </div>
    <p class="auth-verify-email__text">
      {{ $t('common.text.verify_email') }}
    </p>
    <div class="pt-6">
      <BaseTextField
        v-model="code"
        :title="$t('common.titles.verification_code')"
        :placeholder="$t('common.placeholders.verification_code')"
        input-class="w-full"
        pattern="[0-9]"
        type="tel"
        @keyupEnter="submit"
      />
      <div v-if="showTimer" class="text-center mt-6">
        <BaseTimer
          classes="bg-primary-50 text-primary w-[150px] justify-center mx-auto"
          :total-time="29"
          @finished="timerFinished"
        />
      </div>
      <BlockAuthTextAction
        v-else
        :text="$t('common.text.not_receive_code')"
        :action="$t('common.actions.request_again')"
        :loading="requestAgainLoading"
        @clicked="requestAgain"
      />
      <BaseButton
        class="w-full mt-6"
        :label="$t('common.actions.verify_email')"
        size="lg"
        shadow
        :loading="loading"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import BaseTitleAuth from '@design-system/base/title/title-auth'
import BlockAuthTextAction from '@design-system/block/auth/auth-text-action'
import { mapActions } from 'vuex'
import BaseTimer from '@design-system/base/timer/timer'

export default {
  name: 'SectionAuthVerifyEmail',
  components: {
    BlockAuthTextAction,
    BaseTitleAuth,
    BaseTimer
  },
  props: {
    wrapper: {
      type: String,
      default: 'page'
    }
  },
  data () {
    return {
      code: '',
      loading: false,
      requestAgainLoading: false,
      showTimer: true
    }
  },
  computed: {
    email () {
      return this.$auth.$storage.getUniversal('EMAIL_REGISTRATION')
    },
    forget () {
      return this.$route.query?.type === 'forget'
    }
  },
  methods: {
    ...mapActions('authentication', ['loginByVerifyCode', 'emailRegistration']),
    timerFinished () {
      this.showTimer = false
    },
    async submit () {
      this.loading = true
      const email_verification_code = this.code
      const email = this.email
      try {
        const data = await this.loginByVerifyCode({
          email_verification_code,
          email,
          auth_method: 'COOKIE_AND_TOKEN',
          set_password: true
        })
        if (data?.token) {
          this.$toast.global.app_success({
            message: this.$t('common.messages.login_success')
          })
          this.$emit('submitted', data.set_password_allow_code || null)
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async requestAgain () {
      try {
        this.requestAgainLoading = true
        const email = this.email
        const data = await this.emailRegistration({ email })
        if (data?.email) {
          this.$toast.global.app_success({
            message: this.$t('common.messages.email_send')
          })
        }
        this.showTimer = true
      } catch ($e) {
      } finally {
        this.requestAgainLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.auth-verify-email {
  @apply flex flex-col space-y-6 justify-center;
  &--dialog {
    @apply justify-start;
  }
  &__edit {
    @apply flex justify-between items-center space-x-2;
  }
  &__text {
    @apply text__headline-5-xs--semibold text-center;
  }
}
</style>
