<template>
  <div class="relative">
    <!--    activator sidebar slot-->
    <div ref="activator" class="cursor-pointer leading-3">
      <slot name="activator" :toggleSidebar="toggleSidebar" />
    </div>
    <!--    black transparent for sidebar-->
    <transition name="fade">
      <div v-if="sidebarOpen" class="sidebar" :class="['sidebar--'+ type]" />
    </transition>

    <transition :name="type === 'sidebar' ? 'slide-to-right' :'slide-to-left'">
      <div v-show="sidebarOpen" ref="sidebar" class="sidebar__front" :class="['sidebar__front--'+ type]">
        <!--        close sidebar by click on this slot or click outside-->
        <div ref="close">
          <slot name="close" />
        </div>
        <!--        content of sidebar-->
        <slot name="sidebar" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BaseDrawer',
  props: {
    type: {
      type: String,
      default: 'sidebar',
      valid: ['sidebar', 'chat']
    }
  },
  data () {
    return {
      sidebarOpen: false,
      classArray: [
        'w-full',
        'h-full',
        'overflow-hidden'
      ]
    }
  },
  watch: {
    sidebarOpen (val) {
      if (val) {
        this.fixedBody()
        document.addEventListener('click', this.closeSidebar)
      } else {
        this.releaseBody()
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeSidebar)
  },

  methods: {
    ...mapActions('liveChat', ['showLiveChat']),
    fixedBody () {
      for (const i in this.classArray) {
        document.body.classList.add(this.classArray[i])
      }
    },
    releaseBody () {
      for (const i in this.classArray) {
        document.body.classList.remove(this.classArray[i])
      }
    },
    toggleSidebar () {
      this.sidebarOpen = !this.sidebarOpen
    },
    closeSidebar (e) {
      if (
        (!this.$refs.activator.contains(e.target) &&
              !this.$refs.sidebar.contains(e.target)) ||
          this.$refs.close.contains(e.target)
      ) {
        this.$emit('sidebarClosed')
        this.sidebarOpen = false
        document.removeEventListener('click', this.closeSidebar)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  height: -moz-available;
  height: -webkit-stretch;
  height: stretch;
  @apply fixed w-full top-0 z-40;
  &--sidebar {
    @apply left-0 bg-transparent-black;
  }

  &--chat {
    @apply right-0;
  }

  &__front {
    height: -moz-available;
    height: -webkit-stretch;
    height: stretch;
    @apply fixed bg-white z-50;
    &--sidebar {
      @apply max-w-max rounded-r-2xl top-0 left-0;
    }

    &--chat {
      @apply w-full lg:w-[510px] lg:rounded-l-2xl lg:p-6 right-0 lg:shadow-cart-selected top-0;
    }
  }
}
</style>
