// links
import { parseParamsToRoute } from '@helper/utils'
import * as routes from './common-routes'

export const tutor_list_params = (nuxt, course) => {
  if (isRelative(nuxt, 'tutor_list')) {
    return nuxt.localePath(routes.tutor_list.name) + '/' + parseParamsToRoute(course?.link?.parameters)
  } else {
    return nuxt.$config.baseUrl + '/' + nuxt.$i18n.locale + routes.tutor_list.name + '/' + parseParamsToRoute(course?.link?.parameters)
  }
}
export const tutor_profile_link = (nuxt, username) => {
  if (isRelative(nuxt, 'tutor_profile')) {
    return nuxt.localePath({ name: routes.tutor_profile.name, params: { name: username } })
  } else {
    return nuxt.$config.baseUrl + '/' + nuxt.$i18n.locale + routes.tutor_profile.path + '/' + username
  }
}
export const tutor_request_tracking_link = (nuxt, id) => {
  if (isRelative(nuxt, 'tutor_request_tracking')) {
    return nuxt.localePath({ name: routes.tutor_request_tracking.name, params: { id } })
  } else {
    return nuxt.$config.baseUrl + '/' + nuxt.$i18n.locale + routes.tutor_request_tracking.path + id
  }
}
export const absolute = (nuxt, route) => {
  if (isRelative(nuxt, route)) {
    return nuxt.localePath(routes[route].name)
  } else {
    return nuxt.$config.baseUrl + '/' + nuxt.$i18n.locale + routes[route].path
  }
}

export const mag_link = (nuxt, route) => {
  return nuxt.$config.baseUrl + routes[route].path
}
const isRelative = (nuxt, route) => {
  const link = nuxt.$router.resolve({
    name: routes[route].name + '___' + nuxt.$i18n.locale
  })
  return link && link.href !== '/'
}
