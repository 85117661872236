<template>
  <span class="activity-icon" :class="[`bg-size--${bgSize}`,bgColor]">
    <BaseIcon
      :name="name"
      :color="color"
      :mode="mode"
      :size="size"
      :classes="classes"
    />
  </span>
</template>

<script>
export default {
  name: 'BaseIconWithBG',
  props: {
    name: {
      type: String,
      default: 'user'
    },
    color: {
      type: String,
      default: 'primary'
    },
    mode: {
      type: String,
      default: 'round',
      validate: ['round', 'outlined', 'sharp']
    },
    size: {
      type: String,
      default: 'md',
      validate: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', 'xs-sm', 'sm-md', 'xs-md', 'md-xl', 'rating-star']
    },
    classes: {
      type: String,
      default: ''
    },
    bgSize: {
      type: String,
      default: 'sm',
      validate: ['md', 'lg', 'sm', 'sm-lg']
    },
    bgColor: {
      type: String,
      default: 'orange'
    },
    bgClasses: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "icon-mixins";

$sizes: sm 10 10,
md 12 12,
lg 16 16,
sm-lg 10 16;
@include square($sizes);
.activity-icon {
  @apply rounded-lg  flex justify-center items-center;
}

</style>
