import {
  LIVECHAT_CHAT_LIST, LIVECHAT_READ_MESSAGES,
  LIVECHAT_CREATE_MESSAGE, LIVECHAT_MESSAGE_LIST, LIVECHAT_UNREAD_MESSAGE, LIVECHAT_PRESENCE_STATUS
} from '../endpoints'
import {
  STORE_CHAT_LIST,
  STORE_MESSAGE_LIST,
  STORE_UNREAD_MESSAGE,
  STORE_LIVE_CHAT_VISIBILITY,
  STORE_PRESENCE_STATUS,
  STORE_STREAM_MESSAGE,
  STORE_MESSAGE_LIST_CONVERSATION_SELECTED,
  STORE_NEW_MESSAGE,
  STORE_LIVE_CHAT_LOADING,
  UPDATE_CHAT_LIST_UNREAD_MESSAGE,
  STORE_MORE_MESSAGE_LOADING
} from '../mutation-types'

export async function fetchChatList ({ commit, dispatch }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: LIVECHAT_CHAT_LIST.url,
      query: payload
    })
    const data = response?.data
    if (data) {
      commit(STORE_CHAT_LIST, data)
    }
  } catch (e) {
  } finally {
    dispatch('storeLoading', false)
  }
}

export async function fetchUnreadMessage ({ commit }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: LIVECHAT_UNREAD_MESSAGE.url,
      query: payload
    })
    const data = response?.data
    if (data) {
      commit(STORE_UNREAD_MESSAGE, data)
    }
  } catch (e) {
  }
}

export async function fetchMessageList ({ commit, dispatch }, payload) {
  try {
    const response = await this.$api.get({
      url: LIVECHAT_MESSAGE_LIST.url,
      query: payload
    })
    const data = response?.data
    if (data) {
      commit(STORE_MESSAGE_LIST, data)
      return data
    }
  } catch (e) {
    return false
  } finally {
    dispatch('storeLoading', false)
    dispatch('updateMoreMessageLoading', false)
  }
}

export async function createNewMessage ({ commit, dispatch }, payload) {
  try {
    const response = await this.$api.post({
      url: LIVECHAT_CREATE_MESSAGE,
      payload
    })
    const data = response?.data
    if (data) {
      commit(STORE_NEW_MESSAGE, data)
      return data
    }
  } catch (e) {
  } finally {
    dispatch('storeLoading', false)
  }
}

export function storeConversationSelected ({ commit }, payload) {
  commit(STORE_MESSAGE_LIST_CONVERSATION_SELECTED, payload)
}
export function storeLoading ({ commit }, payload) {
  commit(STORE_LIVE_CHAT_LOADING, payload)
}
export function storeChatStatus ({ commit }, payload) {
  commit(STORE_LIVE_CHAT_VISIBILITY, payload)
}
export function storeUnreadMessage ({ commit }, payload) {
  commit(STORE_UNREAD_MESSAGE, payload)
}
export function storeUnreadMessageChatList ({ commit }, payload) {
  commit(STORE_UNREAD_MESSAGE, payload)
}

export function updateChatListUnreadMessage ({ commit }, payload) {
  commit(UPDATE_CHAT_LIST_UNREAD_MESSAGE, payload)
}
export function updateStreamMessage ({ commit }, payload) {
  commit(STORE_STREAM_MESSAGE, payload)
}

export async function showLiveChat ({ commit, dispatch }, payload) {
  dispatch('storeChatStatus', payload.chatStatus)
  if (payload.chatStatus.type === 'conversation') {
    commit(STORE_MESSAGE_LIST_CONVERSATION_SELECTED, payload.chatStatus.chatUserName)
    await dispatch('fetchMessageList', payload.query)
  } else if (payload.chatStatus.type === 'chatList') {
    await dispatch('fetchChatList', payload.query)
  }
}

export async function presenceStatus ({ commit, dispatch }, payload) {
  try {
    const response = await this.$api.post({
      url: LIVECHAT_PRESENCE_STATUS,
      payload
    })
    const data = response?.data
    if (data) {
      commit(STORE_PRESENCE_STATUS, data)
      return data
    }
  } catch (e) {
  } finally {
    dispatch('storeLoading', false)
  }
}
export async function postReadMessages ({ dispatch }, payload) {
  try {
    const response = await this.$api.post({
      url: LIVECHAT_READ_MESSAGES,
      payload
    })
    const data = response?.data
    if (data) {
      return data
    }
  } catch (e) {
  } finally {
    dispatch('storeLoading', false)
  }
}
export function updateMoreMessageLoading ({ commit }, payload) {
  commit(STORE_MORE_MESSAGE_LOADING, payload)
}
