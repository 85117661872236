import Vue from 'vue'
import Toasted from 'vue-toasted'
Vue.use(Toasted)

// Lets Register a Global Error Notification Toast.
// options to the toast
const options = {
  type: 'error',
  className: 'toast-app-error'
}
const success_options = {
  type: 'success',
  className: 'toast-app-success'
}
const warning_options = {
  type: 'info',
  className: 'toast-app-warning'
}
const info_options = {
  type: 'info',
  className: 'toast-app-info'
}
// register the error custom toast
Vue.toasted.register('app_error',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Oops.. Something Went Wrong..'
    }

    // if there is a message show it with the message
    return `    <div class="flex items-center space-x-4">
      <div class="material-icons-outlined  text-error">
        cancel
      </div>
      <div>
        <p class="text__body-1-md--semibold text-title mb-1">
          Error
        </p>
        <p class="lg:text__body-3-md--regular text__caption-xs--regular text-body-1">
          ${payload.message}
        </p>
      </div>
    </div>`
  },
  options
)
// register the success custom toast
Vue.toasted.register('app_success',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'It\'s done successfully.'
    }

    // if there is a message show it with the message
    return `    <div class="flex items-center space-x-4">
      <div class="material-icons-outlined text-success toast-icon">
        check_circle
      </div>
      <div>
        <p class="text__body-1-md--semibold text-title mb-1">
          Success
        </p>
        <p class="lg:text__body-3-md--regular text__caption-xs--regular text-body-1">
          ${payload.message}
        </p>
      </div>
    </div>`
  },
  success_options
)
// register the info custom toast
Vue.toasted.register('app_info',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Oops.. Something Went Wrong..'
    }

    // if there is a message show it with the message
    return `    <div class="flex items-center space-x-4">
      <div class="material-icons-outlined text-primary toast-icon">
        info
      </div>
      <div>
        <p class="text__body-1-md--semibold text-title mb-1">
          Info
        </p>
        <p class="lg:text__body-3-md--regular text__caption-xs--regular text-body-1">
          ${payload.message}
        </p>
      </div>
    </div>`
  },
  info_options
)
// register the warning custom toast
Vue.toasted.register('app_warning',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Oops.. Something Went Wrong..'
    }

    // if there is a message show it with the message
    return `    <div class="flex items-center space-x-4">
      <div class="material-icons-outlined text-warning toast-icon">
        info
      </div>
      <div>
        <p class="text__body-1-md--semibold text-title mb-1">
          Warning
        </p>
        <p class="lg:text__body-3-md--regular text__caption-xs--regular text-body-1">
          ${payload.message}
        </p>
      </div>
    </div>`
  },
  warning_options
)
