import { objectToQueryString } from '@helper/utils.js'

const apiFactory = axios => ({
  create ({ url, payload }) {
    return axios.$post(`${url}`, payload)
  },
  post ({ url, payload, errorType = 'toast' }) {
    // return axios.$post(`${url}`, payload, {showNotify})
    return axios.$post(`${url}`, payload, { errorType })
  },
  get ({ url, query, useCache = false, errorType = 'toast' }) {
    const hasQuery = query && Object.keys(query).length > 0
    if (hasQuery) {
      return axios.$get(`${url}?${objectToQueryString(query)}`, { useCache, errorType })
    } else {
      return axios.$get(`${url}`, { useCache, errorType })
    }
  },
  delete ({ url, payload }) {
    return axios.delete(`${url}`, payload)
  }
})
export default ({ $axios }, inject) => {
  // Inject `api` key
  // -> app.$api
  // -> this.$api in vue components
  // -> this.$api in store actions/mutations
  const api = apiFactory($axios)
  inject('api', api)
}
