export function getMeta (state) {
  return state.meta
}
export function getPagination (state) {
  return state.pagination
}
export function getStatistics (state) {
  return state.statistics
}
export function getTestimonials (state) {
  return state.testimonials
}
export function getBestTeachers (state) {
  return state.bestTeachers
}
export function getFaqs (state) {
  return state.faqs
}
export function getMegaMenu (state) {
  return state.megaMenu
}
export function getMobileMenuState (state) {
  return state.mobileMenuState
}
export function getSeoContent (state) {
  return state.seoContent
}
export function getInternalLinks (state) {
  return state.internalLinks
}
export function getBreadcrumb (state) {
  return state.breadcrumb
}
export function getCurrency (state) {
  return state.currency
}
export function getFooter (state) {
  return state.footer
}
