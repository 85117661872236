import { BEST_TEACHERS, MEGA_MENU, FOOTER } from '../endpoints'
import { STORE_BEST_TEACHERS, STORE_FOOTER, STORE_MEGA_MENU, STORE_MOBILE_MENU_STATE } from '../mutation-types'
export async function fetchBestTeachers ({ commit }, payload) {
  const response = await this.$api.get({
    url: BEST_TEACHERS.url,
    query: { course_group_id: payload },
    useCache: BEST_TEACHERS.cache
  })
  const data = response?.data
  if (data) {
    commit(STORE_BEST_TEACHERS, data)
  }
}
export async function fetchMegaMenu ({ commit }) {
  const response = await this.$api.get({
    url: MEGA_MENU.url,
    useCache: MEGA_MENU.cache
  })
  const data = response?.data
  if (data && data?.children) {
    commit(STORE_MEGA_MENU, data)
  }
}
export async function changeMobileMenu ({ commit }, payload) {
  await commit(STORE_MOBILE_MENU_STATE, payload)
}
export async function asyncFooterData ({ commit }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: FOOTER.url,
      useCache: FOOTER.cache
    })
    const data = response?.data
    if (data) {
      commit(STORE_FOOTER, data)
    }
  } catch (e) {
    console.log(e)
  }
}
