<template>
  <div class="radio-button mb-4">
    <input
      :id="`${name}-${value}`"
      type="radio"
      :name="name"
      :checked="shouldBeChecked"
      :value="value"
      :disabled="isDisable"
      class="radio-button__input"
      @change="updateInput"
    >
    <label :for="`${name}-${value}`" class="radio-button__label">
      <span />
      <span :class="labelClass"> {{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: 'text-body-1'
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shouldBeChecked () {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateInput () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.radio-button {
  @apply flex items-center mr-4;
  &__label {
    @apply flex items-center cursor-pointer;
    & > span:first-of-type {
      @apply w-6 h-6 inline-block mr-1 rounded-full border border-label-text transition hover:scale-110;
      transition: background .2s,
      transform .2s;
    }
  }
  &__input {
    &:checked {
      & ~ label {
        span:first-of-type {
          @apply bg-primary;
          box-shadow: 0px 0px 0px 2px white inset;
        }
        @apply text-primary;
      }
    }
    @apply w-5 h-5 absolute opacity-0;
  }
}
input:checked {
  @apply block;
}
</style>
