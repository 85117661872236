<template>
  <div class="flex items-center justify-center my-6 space-x-3">
    <span class="text__headline-5-xs--semibold">{{ text }}</span>
    <BaseButton
      v-if="link"
      flat
      :label="action"
      size="md"
      :to="link"
      tag="a"
      rel="nofollow"
    />
    <BaseButton
      v-else
      :loading="loading"
      flat
      :label="action"
      size="md"
      @click="$emit('clicked')"
    />
  </div>
</template>

<script>
export default {
  name: 'BlockAuthTextAction',
  props: {
    text: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
