<template>
  <div class="dropdown">
    <BaseLink
      v-for="(item, i) in dropdownList"
      :key="i"
      class="dropdown__item "
      :url="item.to"
    >
      <BaseListItem
        :label="item.label"
        :name="item.icon"
        color="icon-text-field"
        classes="hover--icon-text-field-hover"
        :label-class="labelClass"
      />
    </BaseLink>
  </div>
</template>

<script>
export default {
  name: 'HeaderDropdownNavUser',
  inject: {
    dropdownList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      labelClass: 'text__headline-5-xs--semibold sm:text__body-3-md--semibold'
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown{
  @apply space-y-3 min-w-max;
  &__item {
    @apply flex grow items-center space-x-3;
  }

  &__label {
    @apply text__body-3-md--semibold text-label-text hover:text-primary;
  }
}

</style>
