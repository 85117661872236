<template>
  <div class="base-tab">
    <div
      ref="tab"
      class="flex flex-row relative pb-3 space-x-8"
      :class="{'border-b border-border-text-field': border}"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        ref="baseTabItem"
        class="whitespace-nowrap relative text-center"
        :class="[{'tab--active': item.name === tab},full ? 'w-1/' + items.length : 'w-auto']"
      >
        <div v-if="cursorUp" class="tab-cursor-up">
          <div />
        </div>
        <span
          v-if="item.disabled"
          class="text__body-1--md-regular text-label-text cursor-not-allowed"
        >{{ item.title }}</span>
        <span
          v-else
          class="text__body-2--md--regular text-label-text cursor-pointer"
          @click="changeTab(index,item.name)"
        >{{ item.title }}</span>
      </div>
    </div>
    <div v-for="(item, index) in items" :key="index">
      <slot
        v-if="tab === item.name"
        :name="item.name"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTab',
  props: {
    tab: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    full: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: true
    },
    cursorUp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      right: 0,
      widthTab: 0,
      tabs: 3,
      dontWatchTab: false
    }
  },
  watch: {
    tab () {
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setData()
    })
  },
  methods: {
    setData () {
      const children = this.$refs.baseTabItem
      for (const i in children) {
        const el = children[i].getBoundingClientRect()
        const width = el?.width
        if (width > this.widthTab) {
          this.widthTab = width
        }
      }
      for (const i in children) {
        const el = children[i]
        el.style.width = this.widthTab + 'px'
      }
    },
    changeTab (index, name) {
      this.$emit('changeTab', name)
    }
  }

}
</script>

<style lang="scss" scoped>
.base-tab {
  .tab--active{
    @apply relative;
    & > span {
      @apply text-primary;
    }
    .tab-cursor-up {
      div{
        @apply w-full;
        transition: all 0.2s ease;
      }
    }
  }
  .tab-cursor{
    @apply absolute h-1 bottom-0 bg-primary transition-all;

  }
  .tab-cursor-up{
    @apply absolute top-[-10px] flex justify-center w-full;
    div {
      @apply bg-primary h-1 rounded-b w-0;
      transition: all 0.2s ease;
    }
  }
}

</style>
