<template>
  <div class="auth-agreement">
    <span class="text-body-2"> {{ $t('common.text.agreement') }}
    </span>
    <a
      class="text-primary"
      target="_blank"
      :href="localePath($routes.terms.path)"
    >{{ $t('common.text.terms') }}</a>
  </div>
</template>

<script>
export default {
  name: 'BlockAuthAgreement'
}
</script>

<style scoped lang="scss">
.auth-agreement {
  @apply mt-6 mb-4 text-center text__caption-xs--regular;
}
</style>
