<template>
  <div class="item">
    <div class="item__title">
      <a v-if="item.link" :href="item.link.link_with_query_strings">
        {{ item.anchor }}
      </a>
      <span v-else>{{ item.anchor }}</span>
    </div>
    <ul class="min-h-[160px]">
      <li v-for="child in item.children.slice(0,5)" class="item__item">
        <a :href="child.link.link_with_query_strings" class="text__body-3-md--regular text-body-2">
          {{ child.anchor }}
        </a>
      </li>
    </ul>
    <BaseButton
      flat
      :label="$t('common.actions.view_more')"
      class="self-start -mt-2 cursor-pointer"
      size="sm"
      tag="a"
      :to="item.link ? item.link.link_with_query_strings : getMegaMenu.link.link"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MegaMenuItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('global', ['getMegaMenu'])
  }
}
</script>

<style scoped lang="scss">
.item{
  @apply flex flex-col lg:w-[211px];
  &__title{
    @apply pl-2 border-l-2 border-primary lg:text__body-3-md--semibold text-body-1 lg:mb-3;
  }
  &__item{
    @apply text-body-2 lg:mb-2;
  }
}
</style>
