export function getChatList (state) {
  return state.chatLIst
}
export function getUnreadMessage (state) {
  return state.unreadMessage
}
export function getConversationSelected (state) {
  return state.conversationSelected
}
export function getMessageList (state) {
  return state.messageList
}
export function getChatData (state) {
  return state.messageList?.chat
}
export function getLiveChatVisibility (state) {
  return state.liveChatVisibility
}
export function getLiveChatLoading (state) {
  return state.liveChatLoading
}
export function getPresenceStatus (state) {
  return state.presenceStatus
}
export function getMoreMessageLoading (state) {
  return state.moreMessageLoading
}
