const detectMobileScreen = () => window.innerWidth <= 768
const detectTabletScreen = () => window.innerWidth <= 1023
const detectDesktopScreen = () => window.innerWidth >= 1280
export default {
  name: 'detectDeviceMixin',
  data () {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: false
    }
  },
  mounted () {
    window.addEventListener('resize', this.resize)
    this.isMobile = detectMobileScreen()
    this.isTablet = detectTabletScreen()
    this.isDesktop = detectDesktopScreen()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      this.isMobile = detectMobileScreen()
      this.isTablet = detectTabletScreen()
      this.isDesktop = detectDesktopScreen()
    }
  }
}
