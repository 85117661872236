<template>
  <div class="toggle-button">
    <label
      :for="name"
      class="flex items-center cursor-pointer"
    >
      <div class="relative">
        <input
          :id="name"
          v-model="proxyChecked"
          :value="value"
          type="checkbox"
          class="sr-only"
          :name="name"
        >
        <!-- line -->
        <div class="toggle-button__line" />
        <!-- dot -->
        <div class="toggle-button__dot -right-1" />
      </div>
      <div class="mr-3" :class="labelClass">
        {{ label }}
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: 'BaseToggleButton',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    value: {
      type: [Array, String],
      default: null
    },
    checked: {
      type: [Array, Boolean],
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    proxyChecked: {
      get () {
        return this.checked
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-button {
  @apply flex items-center w-full;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  &__line {
    @apply w-10 lg:h-5 h-4 bg-primary-100 rounded-full;
  }
  &__dot {
    @apply absolute lg:w-6 lg:h-6 w-5 h-5 bg-white rounded-full -top-0.5 transition;
  }
  input:checked ~ .toggle-button__dot {
    @apply lg:translate-x-[-120%] translate-x-[-150%];
  }
  input:checked ~ .toggle-button__line {
    @apply bg-primary;
  }
}
</style>
