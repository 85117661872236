<template>
  <img
    fetchpriority="high"
    :src="dataSrc"
    :alt="alt"
    :data-src="dataSrc"
    :width="width"
    :height="height"
    :class="[{'lazyload': lazy}]"
  >
</template>

<script>
import { isArray } from 'lodash'
import detectDeviceMixin from '@helper/detectDeviceMixin'
export default {
  name: 'BaseImg',
  mixins: [detectDeviceMixin],
  props: {
    src: {
      type: [String, Array],
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    },
    preload: {
      type: Boolean,
      default: false
    },
    sizes: {
      type: Object,
      default: () => {
        return {
          lg: '',
          xs: ''
        }
      }
    }
  },
  head () {
    if (this.preload) {
      return {
        link: [
          {
            rel: 'prefetch',
            href: this.dataSrc
          }
        ]
      }
    }
  },
  computed: {
    dataSrc () {
      if (isArray(this.src)) {
        return this.isMobile ? this.src[0] : this.src[1]
      } else {
        return this.src
      }
    },
    width () {
      return this.isMobile ? this.sizes.xs[0] : this.sizes.lg[0]
    },
    height () {
      return this.isMobile ? this.sizes.xs[1] : this.sizes.lg[1]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
