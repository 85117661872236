// TODO add sentry wehn u need
export default function ({ $sentry }, inject, error) {
  const logger = {
    captureException: ({ error = {} }) => {
      if ($sentry) {
        $sentry.captureException(error)
      } else {
        console.info('cant call captureException')
        if (process.env.NODE_ENV === 'development') { console.trace(error) }
      }
    }
  }
  inject('logger', logger)
}
