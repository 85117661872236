<template>
  <div class="title-line" :class="textStyle">
    <div class="title-line__border" />
    <div class="title-line__text" :class="background">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTitleLine',
  props: {
    text: {
      type: String,
      default: ''
    },
    textStyle: {
      type: String,
      default: 'text__caption-xs--bold lg:text__body-1-md--semibold text-body-1'
    },
    background: {
      type: String,
      default: 'bg-white'
    }
  }
}
</script>

<style scoped lang="scss">
.title-line {
  @apply relative overflow-hidden pb-3 w-[70%] lg:w-full mx-auto;
  &__border {
    @apply h-6 border-b border-label-text w-full
  }
  &__text {
    @apply absolute left-0 right-0 text-center top-[35%] lg:top-[20%] w-[80px] lg:w-[120px] h-8 mx-auto z-10;
  }
}
</style>
