<template>
  <section class="mega-menu-mobile ">
    <div v-for="item in menuData" class="">
      <div
        v-if="getMobileMenuState.layout === 'firstLevel'"
        class="flex justify-between items-center py-3"
        @click="showChildren(item,$event)"
      >
        <span class="text__headline-4-xs--regular text-body-1">{{ item.anchor }}</span>
        <BaseIcon
          size="lg"
          name="expand_more"
          class="cursor-pointer rotate-[270deg]"
          color="teal"
        />
      </div>
      <div v-else class="mt-3">
        <a class="block w-full h-full pb-3" :href="item.link.link_with_query_strings">{{ item.anchor }}</a>
      </div>
      <hr class=" w-full border-border-cart ">
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MegaMenuMobile',
  data () {
    return {
      menuList: [],
      level: 'firstLevel'
    }
  },
  computed: {
    ...mapGetters('global', ['getMegaMenu', 'getMobileMenuState']),
    menuData () {
      return this.getMobileMenuState.layout === 'firstLevel' ? this.getMegaMenu.children : this.menuList
    }
  },
  methods: {
    ...mapActions('global', ['changeMobileMenu']),
    showChildren (item, e) {
      this.menuList = item.children
      this.level = 'children'
      e.stopPropagation()
      const state = {
        menu: 'megaMenu',
        layout: 'children',
        title: item.anchor
      }
      this.changeMobileMenu(state)
    }
  }
}
</script>

<style scoped lang="scss">
.mega-menu-mobile {
  @apply flex flex-col w-[280px] px-4 mt-3 h-screen overflow-scroll;
}
</style>
