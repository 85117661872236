<template>
  <div class="select-box" :class="inputClass">
    <div class="select-box__title">
      {{ title }}
    </div>
    <div
      :class="[
        {'select-box__wrapper--error': messageType === 'error'},
        {'select-box__wrapper--warning': messageType === 'warning'},
        {'select-box__wrapper--disabled': disabled},
        {'select-box__wrapper--disabled': disabled},
        {'select-box__wrapper--flat': flat},
        inputClass]"
      class="select-box__wrapper"
    >
      <div class="select-box__text flex-1">
        <input
          :id="name"
          :name="name"
          class="select-box__input"
          :disabled="disabled"
          readonly
          @click="focusInput"
          @blur="blurInput"
        >
        <span v-if="!value || !value.value" class="select-box__text--placeholder">
          <BaseIcon v-if="iconLeft" :name="iconLeft" size="xs" color="label-text" />
          <span>{{ placeholder }}</span>
        </span>
        <label v-else class="text" :for="name">
          {{ value.name }}
        </label>
      </div>
      <div class="select-box__icon-right">
        <BaseIcon
          name="expand_more"
          size="md"
          color="icon-text-field"
          classes="hover--icon-text-field-hover"
          class="transition"
          :class="{'rotate-180': isFocused}"
        />
      </div>
    </div>
    <div
      v-if="message"
      :class="[`text-${messageType}`]"
      class="select-box__message"
    >
      <span class="flex items-center space-x-2">
        <BaseIcon name="info" size="xs" :color="messageType" />
        <span>{{ message }}</span>
      </span>
    </div>
    <transition name="fade">
      <div v-if="isFocused" class="select-box__options">
        <div class="scroll-custom">
          <div
            v-for="(option,key) in options"
            :key=key
            class="items"
            :class="{'active': value && value.value && option.value === value.value}"
            @click="updateOption(option)"
          >
            {{ option.name }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseSelectBox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    name: {
      type: String,
      default: 'name1'
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    messageType: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    inputClass: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  methods: {
    focusInput () {
      this.isFocused = !this.isFocused
    },
    blurInput () {
      this.isFocused = false
    },
    updateOption (option) {
      this.$emit('updated', option)
    }
  }
}
</script>

<style scoped lang="scss">
.select-box {
  @apply relative;
  &__wrapper {
    @apply flex items-center bg-white border rounded-2xl border-border-text-field lg:h-14 h-12 px-3 cursor-pointer;
    &--flat {
      @apply border-none h-auto;
    }
    &--warning {
      @apply border-warning;
    }
    &--error {
      @apply border-error;
    }
    &--disabled{
      @apply pointer-events-none bg-border-cart border-border-cart;
    }
  }
  &__title {
    @apply ml-1 mb-2 lg:text__title-input-md--regular text__title-input-xs--regular text-body-2;
  }
  &__text {
    @apply cursor-pointer lg:text__text-input-md--regular text__text-input-xs--regular text-title;
    &--placeholder{
      @apply flex items-center space-x-2 lg:text__placeholder-input-xs--regular
      text__placeholder-input-xs--regular text-label-text;
    }
  }
  &__icon-right {
    @apply transition h-6;
  }
  &__message {
    @apply absolute bottom-[-26px] left-1;
  }
  &__options {
    @apply absolute top-[110%] bg-white py-4 px-4 w-full rounded-2xl border
    border-border-text-field text-body-1 z-50;
    & > div {
      @apply max-h-[200px] overflow-y-auto space-y-2;
    }
    .items {
      @apply cursor-pointer hover:bg-primary-100 rounded-2xl transition pl-3;
      &.active {
        @apply bg-primary-100 text-primary-600;
      }
    }
  }
  &__input {
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:focus,&:focus-visible {
      @apply outline-none border-primary;
    }
    &[disabled=disabled] {
      @apply cursor-not-allowed;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
