import Pusher from 'pusher-js'

export const PusherModule = (token, env) => {
  return new Pusher(env.PUSHER_APP_KEY, {
    cluster: env.PUSHER_APP_CLUSTER,
    authEndpoint: env.PUSHER_APP_AUTH_URL,
    auth: {
      headers: {
        Authorization: token
      }
    }
  })
}
