<template>
  <a :href="localePath($routes.home.name)" rel="home">
    <img
      alt="Ostado Inc."
      v-bind="$attrs"
      :class="classes"
      :src="src"
    >
  </a>
</template>

<script>
export default {
  name: 'BaseAppLogo',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    classes: {
      type: String,
      default: 'mx-auto'
    },
    type: {
      type: String,
      default: 'logo-full',
      valid: ['logo-full', 'logo-name', 'logo']
    }
  },
  computed: {
    src () {
      return `/front-img/${this.type}-${this.color}.svg`
    }
  }
}
</script>

<style scoped>

</style>
