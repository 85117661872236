<template>
  <div class="relative">
    <div class="flex cursor-pointer" @click="toggleMegaMenu">
      <span class="hover:text-title  text__body-2-md--regular text-label-text">
        {{ menuData.anchor }}
      </span>
      <BaseIcon
        color="icon-text-field"
        name="expand_more"
        size="md"
      />
    </div>
    <transition name="drop">
      <div v-show="showMenu" class="mega-menu">
        <div class="grid grid-cols-5 gap-6 p-2">
          <MegaMenuItem v-for="(item,key) in menuData.children" :key="key" :item="item" />
        </div>
        <BaseButton
          v-if="menuData && menuData.link"
          class="w-[160px]"
          :label="menuData.anchor "
          color="primary"
          outline
          size="sm"
          tag="a"
          :to="menuData.link.link"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import MegaMenuItem from './mega-menu-item.vue'

export default {
  name: 'MegaMenu',
  components: { MegaMenuItem },
  props: {
    menuData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    dropdownPosition () {
      return '-left-80 top-12'
    }
  },
  watch: {
    showMenu (val) {
      if (val) {
        document.addEventListener('click', this.closeMegaMenu)
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeMegaMenu)
  },
  methods: {
    toggleMegaMenu () {
      this.showMenu = !this.showMenu
    },
    closeMegaMenu (e) {
      if (!this.$el.contains(e.target)) {
        this.showMenu = false
        document.removeEventListener('click', this.closeMegaMenu)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mega-menu{
  @apply absolute -left-80 top-12 bg-primary-50 w-[1200px] p-6 rounded-2xl;
}
</style>
