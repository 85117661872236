/**
 * check two array and remove duplicate
 * @param {array} baseArray
 * @param {array} array
 * @returns {array} remove duplicate array
 */
import moment from 'moment'
function checkDiff (baseArray, array) {
  return baseArray
    .map((errorVal) => {
      return array.includes(errorVal)
    })
    .includes(true)
}

/**
 * convert object to query string
 * @param {Object} obj - simple object {key:value,...}
 * @returns {string} - {key:value} => "key=value"
 */
function objectToQueryString (obj) {
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      if (obj[p] !== null && obj[p] !== undefined) {
        if (Array.isArray(obj[p])) {
          str.push(arrayToQueryString(obj[p], p))
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      }
    }
  }
  return str.length > 0 ? str.join('&') : ''
}

/**
 * pass query string and give object
 * @param {string} querystring - foo=bar&baz=jaz
 * @returns {{}} => {foo:bar,baz:jaz}
 */
const parseParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring)

  const obj = {}

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key)
    } else {
      obj[key] = params.get(key)
    }
  }

  return obj
}
/**
 * pass array and give query string
 * @param {array} array
 * @param {string} name
 * @returns {*}
 */
const arrayToQueryString = (array, name) => {
  return array.map(id => name + '[]=' + id).join('&')
}
const arrayListToQueryString = (array, name) => {
  return `${name}[${array}]`
}
/**
 * check status from server
 * @param {string} status
 * @returns {boolean|*}
 */
const checkStatus = (status) => {
  if (typeof status === 'string') {
    return status === 'SUCCESS'
  } else {
    return status
  }
}
/**
 * send response error and give status code
 * @param {object} e - error from axios
 * @returns {number | ((code: number) => any)}
 */
const checkErrorStatus = (e) => {
  return e.response?.status || null
}
/**
 * callback list validation from backend
 * @param error - response errors from server
 * @param setForm - callback function loop on errors
 */
const validationBackend = (error, setForm) => {
  const errors = error.response.data.errors
  if (errors && errors.length > 0) {
    setForm('', errors[0].message)
  } else {
    setForm('', error.response.data.message)
  }
}
/**
 * convert minute tp midsections
 * @param minute
 * @returns {number}
 */
const minuteToMilliSecond = (minute) => {
  return 1000 * 60 * minute
}
const isEmptyObject = (object) => {
  return !Object.getOwnPropertyNames(object).length && !Object.getOwnPropertySymbols(object).length
}
/**
 * set data in children nested data
 * @param node
 * @returns {*}
 */
const setDataTree = (node) => {
  if (!node) {
    return
  }
  for (const item of node) {
    item.hasSelected = false
    item.opened = false
    if (item.children?.length) {
      item.children = setDataTree(item.children || [])
    }
  }
  return node
}
/**
 * loop for   extract ids
 * @param array
 * @returns {*[]}
 */
const extractAllSelectedNodes = (array) => {
  const ids = []
  for (const item of array) {
    extractRecursiveSelectedNodes(item.children, ids)
  }
  return ids
}
/**
 * extract ids
 * @param node
 * @param checkArray
 * @returns {{node, checkArray}}
 */
const extractRecursiveSelectedNodes = (node, checkArray) => {
  if (!node) {
    return
  }
  for (const item of node) {
    if (item.children?.length) {
      extractRecursiveSelectedNodes(item.children, checkArray)
    } else if (item.course_id && item.checked) {
      checkArray.push(item.course_id)
    }
  }
  return { node, checkArray }
}

/**
 * split route buy any key
 */
const splitRouteBy = ({ fullPath, splitBy }) => {
  return fullPath.split(`/${splitBy}/`)[1]
}
/**
 *
 * @param url: 'dynamic-route1/dynamic-route2/dynamic-route3
 * @returns {param1: 'dynamic-route1', param2: 'dynamic-route2', ...}
 */
const parseRouteToParams = (url) => {
  const params = url.split('/')
  const obj = {}

  params.forEach((element, index) => {
    obj['param' + (index + 1)] = element
  })

  return obj
}
const ArrayToParams = (params) => {
  const obj = {}
  params.forEach((element, index) => {
    obj['param' + (index + 1)] = element
  })

  return obj
}
/**
 *
 * @param params: {param1: 'dynamic-route1', param2: 'dynamic-route2', ...}
 * @returns 'dynamic-route1/dynamic-route2/dynamic-route3'
 */
const parseParamsToRoute = (params) => {
  let url = ''
  for (const key in params) {
    if (params[key]) {
      url = url + '/' + params[key]
    }
  }
  return url.slice(1)
}
const queryStringToJson = (queryString) => {
  const pairs = queryString.split('&')
  const result = {}
  pairs.forEach(function (pair) {
    pair = pair.split('=')
    result[pair[0]] = decodeURIComponent(pair[1] || '')
  })
  return result
}
/**
 * set more data for common data
 * @param oldResponse
 * @param response
 * @returns {*&{data: *[]}}
 */
const setMore = ({ oldResponse, response }) => {
  const localValue = { ...response }
  delete localValue.data
  const oldList = [...oldResponse.data]
  const newList = oldList.concat(response.data)
  return {
    ...localValue,
    data: newList
  }
}
/**
 * set see more data for package
 * @param oldResponse
 * @param response
 * @returns {*&{data: *[]}}
 */
const setMorePackage = ({ oldResponse, response }) => {
  const localValue = { ...response }
  const oldList = oldResponse.data.teacher_video_packages
  const newList = oldList.concat(response.data.teacher_video_packages)
  delete localValue.data.teacher_video_packages
  return {
    ...localValue,
    data: {
      teacher_video_packages: newList
    }
  }
}

/**
 * Duration Converter
 * @param duration
 * @param isFormat
 * @returns {*|string}
 */
const showDuration = (duration, isFormat = false) => {
  const MINUTES = duration // 6.55
  const m = MINUTES % 60
  const h = (MINUTES - m) / 60
  const HHMM = h.toString() + ':' + (m < 10 ? '0' : '') + m.toString()
  const listTime = HHMM.split(':')
  const hStr = listTime[0] && listTime[0] > 0 ? `${listTime[0]} ساعت ` : ''
  const mStr = listTime[1] && listTime[1] > 0 ? `${listTime[1]} دقیقه ` : ''
  const stringFormat = hStr !== '' && mStr !== '' ? hStr + ' و ' + mStr : hStr + mStr
  return isFormat ? stringFormat : HHMM
}
/**
 * check nested object has params or not
 * @param obj
 * @returns {boolean}
 */
function checkNested (obj /*, level1, level2, ... levelN */) {
  const args = Array.prototype.slice.call(arguments, 1)

  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }
  return true
}

function stateLoadMore ({ length, perPage }) {
  if (length > 0) {
    return length >= perPage
  } else {
    return false
  }
}

// Scrolls to an id
function scrollById ({ id, offset }) {
  if (!window) {
    return
  }
  const element = document.getElementById(id)
  window.scrollTo({ top: element.offsetTop - offset, behavior: 'smooth' })
}
/**
 * pass array give url
 * @param link_parameters
 * @returns {string|*}
 */
function arrayToUrl (link_parameters) {
  if (!link_parameters || !link_parameters.length) { return '' }
  return link_parameters.map(l => l).join('/')
}
const urlParams = (url, params) => {
  let out = url
  for (const p in params) {
    const x = `{${p}}`
    out = out.replace(x, params[p])
  }
  return out
}
const cleanObject = (obj) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}
const cloneObjectWithException = (obj, exception) => {
  const p = { ...obj }
  delete p[exception]
  return p
}

const byteConverter = (bytes, decimals, only) => {
  const K_UNIT = 1024
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

  if (bytes === 0) { return '0 Byte' }

  if (only === 'MB') { return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB' }

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT))
  const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i]

  return resp
}
const calculateTime = (date) => {
  const now = new Date().getTime()
  const target = new Date(date).getTime()
  const differenceTime = Math.abs(now - target)

  const ms_Min = 60 * 1000
  const ms_Hour = ms_Min * 60
  const ms_Day = ms_Hour * 24
  const ms_Mon = ms_Day * 30
  const ms_Yr = ms_Day * 365
  if (differenceTime < ms_Min) {
    return Math.round(differenceTime / 1000) + ' seconds ago'
  } else if (differenceTime < ms_Hour) {
    return Math.round(differenceTime / ms_Min) === 1
      ? '1 minute ago'
      : Math.round(differenceTime / ms_Min) + ' minutes ago'
  } else if (differenceTime < ms_Day) {
    return Math.round(differenceTime / ms_Hour) === 1
      ? '1 hour ago'
      : Math.round(differenceTime / ms_Hour) +
            ' hours ago'
  } else if (differenceTime < ms_Mon) {
    return Math.round(differenceTime / ms_Day) === 1
      ? '1 day ago'
      : Math.round(differenceTime / ms_Day) + ' days ago'
  } else if (differenceTime < ms_Yr) {
    return 'Around ' + (Math.round(differenceTime / ms_Mon) === 1
      ? '1 month ago'
      : Math.round(differenceTime / ms_Mon) + ' months ago')
  } else {
    return 'Around ' + (Math.round(differenceTime / ms_Yr) === 1
      ? '1 year ago'
      : Math.round(differenceTime / ms_Yr) + ' years ago')
  }
}

const validateRequired = (form, optionals) => {
  if (!form) {
    return false
  }
  const requiredForm = { ...form }
  for (const x in optionals) {
    if (requiredForm[optionals[x]] !== undefined) {
      delete requiredForm[optionals[x]]
    }
  }
  const isEmpty = Object.values(requiredForm).includes('')
  return !isEmpty
}
const detectMobileScreen = () => window.innerWidth <= 768

const minutesToHumanTime = (time) => {
  const hours = Math.floor(time / 60)
  const minutes = time % 60
  const hoursLabel = hours > 1 ? 'hours' : 'hour'
  const minutesLabel = minutes > 1 ? 'minutes' : 'minute'
  return `${hours > 0 ? `${hours} ${hoursLabel}` : '0'}  ${minutes > 0 ? ` ${minutes} ${minutesLabel}` : ''}`
}
const sortArrayByDate = (list) => {
  const sortedList = list.sort(function compare (a, b) {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateA - dateB
  })
  return sortedList
}
const stringTime = (time, format) => {
  moment.locale('en')
  return moment(time).format(format)
}
const shortenText = (str, n, useWordBoundary) => {
  if (str.length <= n) { return str }
  const subString = str.slice(0, n - 1) // the original check
  return (useWordBoundary
    ? subString.slice(0, subString.lastIndexOf(' '))
    : subString) + '&hellip;'
}

export {
  arrayToUrl,
  stateLoadMore,
  checkNested,
  setMorePackage,
  setMore,
  queryStringToJson,
  splitRouteBy,
  parseRouteToParams,
  parseParamsToRoute,
  checkDiff,
  parseParams,
  objectToQueryString,
  arrayToQueryString,
  arrayListToQueryString,
  checkStatus,
  checkErrorStatus,
  validationBackend,
  minuteToMilliSecond,
  showDuration,
  scrollById,
  urlParams,
  cleanObject,
  ArrayToParams,
  cloneObjectWithException,
  validateRequired,
  byteConverter,
  detectMobileScreen,
  calculateTime,
  minutesToHumanTime,
  sortArrayByDate,
  stringTime,
  shortenText
}
export { extractAllSelectedNodes, setDataTree, isEmptyObject }
