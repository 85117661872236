import { SHOW_MODAL, HIDE_MODAL } from '../mutation-types'

export default {
  [SHOW_MODAL] (state, payload) {
    state.name = payload
  },
  [HIDE_MODAL] (state) {
    state.name = ''
  }
}
