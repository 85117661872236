import {
  STORE_LIST_TEACHERS,
  STORE_TUTOR_CALENDAR, STORE_TUTOR_COURSE_GROUP, STORE_TUTOR_COURSES, STORE_TUTOR_FEEDBACKS,
  STORE_TUTOR_RESUME,
  STORE_TUTOR_SELECTED,
  STORE_TUTOR_SELECT, PUSH_LIST_TEACHERS
} from '../mutation-types'

export default {
  [STORE_TUTOR_SELECTED] (state, payload) {
    state.selectedTeacher = payload
  },
  [STORE_TUTOR_SELECT] (state, payload) {
    state.tutorSelect = payload
  },
  [STORE_LIST_TEACHERS] (state, payload) {
    state.teacherList = payload
  },
  [STORE_TUTOR_CALENDAR] (state, payload) {
    state.teacherCalendar = payload
  },
  [STORE_TUTOR_RESUME] (state, payload) {
    state.resumeItems = payload
  },
  [STORE_TUTOR_COURSES] (state, payload) {
    state.courses = payload
  },
  [STORE_TUTOR_COURSE_GROUP] (state, payload) {
    state.courseGroups = payload
  },
  [STORE_TUTOR_FEEDBACKS] (state, payload) {
    const comments = state?.feedbacks?.comments
    const feedbacks = state?.feedbacks
    if (feedbacks && feedbacks?.teacherId === payload?.teacherId && comments && comments.length > 0) {
      state.feedbacks.comments = [...comments, ...payload.comments]
    } else {
      state.feedbacks = payload
    }
  },
  [PUSH_LIST_TEACHERS] (state, payload) {
    state.teacherList.push(...payload)
  }
}
