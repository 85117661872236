<template>
  <div class="auth-signup">
    <div>
      <BaseTitleAuth :title="$t('common.titles.signup')" class="mb-6" :class="{'lg-block-hidden': wrapper === 'dialog'}" />
      <BlockAuthLoginThirdParty />
    </div>
    <div class="pt-6">
      <client-only>
        <ValidationProvider ref="signup" v-slot="{ errors }" name="email" rules="required|email">
          <BaseTextField
            v-model.trim="email"
            :title="$t('common.titles.email')"
            :placeholder="$t('common.placeholders.email')"
            input-class="w-full"
            :message="errors[0]"
            message-type="error"
            @keyupEnter="submit"
          />
        </ValidationProvider>
        <BlockAuthAgreement />
      </client-only>
      <div>
        <BaseButton
          class="w-full"
          :label="$t('common.actions.next')"
          size="md"
          :loading="loading"
          @click="submit"
        />
      </div>
      <BlockAuthTextAction
        v-if="wrapper === 'page'"
        :text="$t('common.text.have_account')"
        :action="$t('common.actions.login')"
        :link="localePath({name: $routes.login.name, query: $route.query})"
      />
      <BlockAuthTextAction
        v-if="wrapper === 'dialog'"
        :text="$t('common.text.have_account')"
        :action="$t('common.actions.login')"
        @clicked="AuthDialogLogin"
      />
      <BaseButton
        outline
        light
        :label="$t('common.actions.signup_tutor')"
        class="mx-auto w-[fit-content]"
        size="sm"
        tag="a"
        :to="$links.absolute($nuxt, 'start_teaching')"
      />
    </div>
  </div>
</template>

<script>
import BaseTitleAuth from '@design-system/base/title/title-auth'
import BlockAuthLoginThirdParty from '@design-system/block/auth/auth-login-third-party'
import BlockAuthTextAction from '@design-system/block/auth/auth-text-action'
import BlockAuthAgreement from '@design-system/block/auth/auth-agreement'
import { mapActions } from 'vuex'
export default {
  name: 'SectionAuthSignup',
  components: {
    BlockAuthAgreement,
    BlockAuthTextAction,
    BlockAuthLoginThirdParty,
    BaseTitleAuth
  },
  props: {
    wrapper: {
      type: String,
      default: 'page'
    }
  },
  data () {
    return {
      email: '',
      loading: false
    }
  },
  methods: {
    ...mapActions('dialog', ['hideDialog']),
    ...mapActions('authentication', ['emailRegistration']),
    AuthDialogLogin () {
      this.$emit('hide')
      setTimeout(() => {
        this.$nuxt.$emit('AuthDialogLogin')
      }, 500)
    },
    async submit () {
      const res = await this.$refs.signup.validate()
      if (res.valid) {
        this.loading = true
        try {
          const email = this.email
          const data = await this.emailRegistration({ email })
          this.$auth.$storage.setUniversal('EMAIL_REGISTRATION', data.email)
          this.$emit('submitted', data.is_registered)
        } catch (e) {
        } finally {
          this.loading = false
        }
      } else {
        this.$toast.global.app_error({
          message: res.errors[0]
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.auth-signup {
  @apply divide-y divide-border-cart flex flex-col space-y-6;
}
</style>
