export default function () {
  return {
    courses: [],
    loadingList: false,
    typeSelected: null,
    courseId: null,
    parent: null,
    searchTerm: null,
    languageState: null,
    languagesList: [],
    activeReturn: false
  }
}
