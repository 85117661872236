<template>
  <div
    :class="[`tag-${color}`]"
    class="base-tag"
  >
    <slot>
      {{ label }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'BaseTag',
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'teal'
    }
  }
}
</script>

<style scoped lang="scss">
@import "tag-mixins";
.base-tag {
  @apply text-white rounded-2xl px-1 py-[2px] inline-flex items-center justify-center
  lg:text__caption-xs--regular text__caption-1-xs--regular;
  $colors: teal,
  purple,
  orange,
  primary-100,
  pink;
  @include color($colors);
}
</style>
