<template>
  <div class="conversation-placeholder">
    <div class="text-center">
      <BaseIcon name="message" color="label-text" size="big" />
      <p class="conversation-placeholder__text">
        No message has been sent.
      </p>
      <div class="conversation-placeholder__action">
        <BaseButton
          :label="$t('common.actions.close')"
          class="w-full lg:w-[166px]"
          size="sm-md"
          outline
          shadow
          @click="$emit('closeDrawer')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversationPlaceholder'
}
</script>

<style scoped lang="scss">
.conversation-placeholder {
  @apply flex justify-center items-center h-full;
  &__text {
    @apply text__body-2-xs--regular lg:text__body-1-md--regular text-label-text mt-4;
  }
  &__action {
    @apply fixed flex bottom-0 bg-white w-full  p-4 inset-x-0 pb-6 shadow-banner justify-center z-50;
    @screen lg {
      @apply relative mt-12 flex items-center space-x-2 shadow-none p-0 z-0;
    }
  }
}
</style>
