export default function () {
  return {
    meta: {},
    pagination: {},
    statistics: {},
    testimonials: [],
    bestTeachers: [],
    faqs: [],
    megaMenu: {},
    seoContent: {},
    internalLinks: [],
    breadcrumb: [],
    mobileMenuState: {
      menu: 'default', // [default,megaMenu]
      layout: '', // ['',firstLevel,children]
      title: ''// ['',English tutoring,childrenName]
    },
    currency: null,
    footer: {}
  }
}
