<template>
  <BaseCard padding="none" xs-flat class="auth-card">
    <div class="lg-hidden-flex auth-card__xs-logo">
      <BaseAppLogo width="100" height="21" />
    </div>
    <div class="auth-card__left lg-flex-hidden">
      <div>
        <BaseAppLogo width="205" height="48" class="mx-auto" />
        <img
          data-src="@images/common/auth-card.png"
          width="323"
          height="127"
          class="lazyload mt-[96px]"
          alt="auth-card"
        >
      </div>
    </div>
    <div class="auth-card__right">
      <slot />
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: 'SectionAuthCard'
}
</script>

<style scoped lang="scss">
.auth-card {
  @apply lg:flex justify-between w-full h-full lg:w-[770px] lg:min-h-[680px];
  &__left{
    @apply items-center justify-center bg-primary-50 rounded-l-2xl w-1/2;
  }
  &__right {
    @apply w-full lg:w-1/2 p-4 lg:p-8;
  }
  &__xs-logo {
    @apply mt-6 ml-4 mb-5 justify-start;
  }
}
</style>
