<template>
  <section class="h-full">
    <BlockLoadingBlur :show="chatLoading" class="h-full" :slot-wrapper-class="'h-full'">
      <ConversationRow
        v-if="showList"
        @selectChat="selectChat"
        @closeDrawer="closeDrawer"
      />
      <div v-else class="h-full">
        <SectionLiveChatConversation @backToList="showChats" />
      </div>
    </blockloadingblur>
  </section>
</template>

<script>
import ConversationRow from '@design-system/section/live-chat/conversation/conversation-row'
import SectionLiveChatConversation from '@design-system/section/live-chat/conversation/conversation'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionLiveChatInbox',
  components: { ConversationRow, SectionLiveChatConversation },
  data () {
    return {
      showList: true,
      chatLoading: true
    }
  },
  computed: {
    ...mapGetters('liveChat', ['getLiveChatVisibility', 'getLiveChatLoading'])
  },
  watch: {
    getLiveChatVisibility () {
      this.getLiveChatVisibility.type === 'chatList' ? this.showList = true : this.showList = false
    },
    getLiveChatLoading () {
      this.chatLoading = this.getLiveChatLoading
    }
  },
  methods: {
    ...mapActions('liveChat', ['fetchChatList', 'fetchMessageList', 'storeConversationSelected', 'storeLoading', 'storeChatStatus']),
    async getConversation (chatUserName) {
      try {
        this.storeChatStatus({
          visible: true,
          type: 'conversation',
          chatUserName
        })
        this.storeLoading(true)
        await this.storeConversationSelected(chatUserName)
        const resp = await this.fetchMessageList({
          role: 'STUDENT',
          chat_username: chatUserName,
          page: 1,
          per_page: 20
        })
        if (!resp) {
          this.closeDrawer()
        }
      } catch (e) {
      }
    },
    toggle (e) {
      e.stopPropagation()
      this.showList = !this.showList
    },
    selectChat (data) {
      this.toggle(data.e)
      this.getConversation(data.chatUserName)
    },
    showChats (e) {
      this.toggle(e)
      this.getChats()
    },
    closeDrawer () {
      this.$emit('closeDrawer')
    },
    async getChats () {
      this.storeChatStatus({
        visible: true,
        type: 'chatList',
        chatUserName: this.$auth.user.chat_username
      })
      this.storeLoading(true)
      try {
        await this.fetchChatList({
          role: 'STUDENT'
        })
      } catch (e) {
      }
    }

  }
}
</script>

<style scoped lang="scss">

</style>
