<template>
  <div class="live-conversation">
    <div class="live-conversation__header">
      <BaseIcon name="chevron_left" color="label-text" size="sm-md" class="mr-4 cursor-pointer" @click="back($event)" />
      <div class="live-conversation__user">
        <BaseAvatarConversation
          :img-url="picture"
          class="mr-5"
          mode="profile"
        />
        <div class="user-text">
          <span class="user-text__name">{{ name }}</span>
          <span v-if="isOnline" class="user-text__course text-success">{{ $t('common.labels.online') }}</span>
          <span v-else class="user-text__course text-error">{{ $t('common.labels.offline') }}</span>
        </div>
      </div>
      <!--      <BaseButton outline :label="$t('actions.resume')" shadow size="xs-md" />-->
    </div>
    <div ref="scrollContainer" class="live-conversation__body scroll-custom">
      <div v-if="messageList && messageList.length === 0" class="text-center mt-[170px]">
        <BaseIcon name="message" size="2xl" color="label-text" />
        <p class="mt-2">
          {{ $t('common.text.not_message_yet') }}
        </p>
      </div>
      <ConversationShowMore :hide-button="shouldShowMoreButton" @moreMessage="moreMessage" />
      <div>
        <div
          v-for="(messages,messageDate) in messageList"
          :key="messageDate"
          class="mr-2"
        >
          <div v-if="isToday(messageDate)" class="mb-5">
            <BaseTitleLine text="Today" />
          </div>
          <div v-else class="my-6 lg:my-8">
            <ConversationMessageDate :text="messageDate" />
          </div>
          <!--          <ConversationMessage :messages="messages" :message-date="messageDate" @listRendered="renderComplete" />-->
          <div
            v-for="(message,key) in messages"
            :key="key"
            v-for-callback="{ message:message,isLastItem:key === messageList[messageDate].length - 1,
                              isLastDate:Object.keys(messageList).pop() === messageDate,
                              callback: renderComplete}"
          >
            <div v-if="message.type ==='TEXT'">
              <div
                class="live-conversation__message "
                :class="message.who.toUpperCase() !== 'ME' ? 'live-conversation__message--host' : 'live-conversation__message--guest'"
              >
                <span>{{ message.text }}</span>
              </div>
              <p
                class="live-conversation__message-time"
                :class="message.who.toUpperCase() !== 'ME' ? 'host' : 'guest'"
              >
                {{ timeHuman(message.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="live-conversation__footer">
      <ValidationObserver ref="messageForm" v-slot="{ invalid }">
        <div class="relative">
          <ValidationProvider v-slot="{ errors }" name="message" rules="required|min:1|max:1000">
            <BaseTextarea
              v-model="messageText"
              name="message"
              :placeholder="$t('common.placeholders.message_conversation')"
              class="w-full"
              rows="3"
              :message="errors[0]"
              message-type="error"
              @keyupEnter="storeMessage()"
            />
          </ValidationProvider>
          <div class=" lg-block-hidden absolute right-4 bottom-3">
            <BaseButton
              icon-left="send"
              :label="$t('common.actions.send')"
              size="xs"
              :loading="loading"
              :disabled="loading && invalid"
              @click.stop="storeMessage"
            />
          </div>
          <div class="lg-hidden-block">
            <div class="live-conversation__mobile-btn" @click.stop="storeMessage">
              <BaseIcon name="send" size="xs" color="white" />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import BaseAvatarConversation from '@design-system/base/avatar/avatar-conversation'
import BaseTitleLine from '@design-system/base/title/title-line'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import BaseIcon from '@design-system/base/icon/icon'
import ConversationMessageDate from './conversasion-message-date'
import ConversationShowMore from './conversation-show-more'

export default {
  name: 'SectionLiveChatConversation',
  components: {
    BaseIcon,
    BaseAvatarConversation,
    BaseTitleLine,
    ConversationMessageDate,
    ConversationShowMore
  },
  props: {},
  data () {
    return {
      messageText: '',
      avatar: '',
      loading: false,
      page: 1,
      perPage: 20,
      messageList: {},
      total: 0,
      messageNumber: 0,
      unreadMessages: [],
      postedUnreadMessages: []
    }
  },
  computed: {
    ...mapGetters('liveChat', ['getLiveChatVisibility', 'getChatData', 'getMessageList', 'getConversationSelected', 'getMoreMessageLoading']),
    picture () {
      return this.getChatData?.user?.picture?.jpg_80X80?.url
    },
    name () {
      return this.getChatData?.user?.fullname
    },
    isOnline () {
      return this.getChatData?.user?.teacher_status === 'ONLINE'
    },
    shouldShowMoreButton () {
      return this.messageNumber >= this.total
    },
    messageLoading () {
      return this.getMoreMessageLoading
    }
  },
  watch: {
    getMessageList: {
      handler (newValue) {
        if (newValue) {
          this.messageList = JSON.parse(JSON.stringify(newValue.chat_messages))
          this.total = newValue.total
          // this.perPage = newValue.per_page
          this.checkItem()
          for (const messages in this.messageList) {
            for (let i = 0; i < this.messageList[messages].length; i++) {
              const item = this.messageList[messages][i]
              if (item.read_at === null && item.who !== 'ME') {
                this.unreadMessages.push(item.id)
              }
            }
          }
          const symDifference =
              this.unreadMessages.filter(element => !this.postedUnreadMessages.includes(element))
          if (symDifference.length > 0 && this.getLiveChatVisibility.type === 'conversation') {
            this.readRenderedMessage(symDifference)
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('liveChat', ['fetchMessageList', 'createNewMessage', 'postReadMessages', 'updateMoreMessageLoading']),
    async storeMessage () {
      const res = await this.$refs.messageForm.validate()
      if (res) {
        this.loading = true
        try {
          const userName = this.getConversationSelected?.chat_username
          const response = await this.createNewMessage({
            role: 'STUDENT',
            chat_username: userName,
            text_message: this.messageText
          })
          if (response?.message) {
            this.$toast.global.app_success({
              message: response.message
            })
          }
          if (response) {
            this.resetForm()
          }
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    },
    back (e) {
      this.$emit('backToList', e)
    },
    timeHuman (date) {
      return moment(date).format('LT')
    },
    scrollToBottom () {
      const container = this.$refs.scrollContainer
      if (container) {
        setTimeout(() => {
          container.scrollTop = container.scrollHeight - container.clientHeight
        }, 1)
      }
    },
    resetForm () {
      this.messageText = ''
      this.scrollToBottom()
      this.$refs.messageForm.reset()
    },
    isToday (date) {
      const yourDate = new Date(date)
      const today = new Date()
      const sameDate =
          yourDate.getFullYear() === today.getFullYear() &&
          yourDate.getMonth() === today.getMonth() &&
          yourDate.getDate() === today.getDate()
      return sameDate
    },
    calculateNumber (obj) {
      let count = 0
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          count += obj[key].length
        }
      }
      return count
    },
    async fetchMoreMessages (query) {
      try {
        this.updateMoreMessageLoading(true)
        await this.fetchMessageList(query)
      } catch (error) {
        console.error(error)
      }
    },
    moreMessage () {
      this.pastMessages = true
      const userName = this.getConversationSelected?.chat_username
      this.page++
      const query = {
        chat_username: userName,
        role: 'STUDENT',
        page: this.page,
        per_page: this.perPage
      }
      this.fetchMoreMessages(query)
    },
    renderComplete () {
      if (!this.pastMessages) {
        this.scrollToBottom()
      } else {
        this.pastMessages = false
      }
    },
    checkItem () {
      this.messageNumber = this.calculateNumber(this.messageList)
    },
    readRenderedMessage (messages) {
      const userName = this.getConversationSelected?.chat_username
      try {
        this.postReadMessages({
          role: 'STUDENT',
          chat_username: userName,
          chat_message_id: messages
        })
        this.postedUnreadMessages = this.postedUnreadMessages.concat(messages)
        this.unreadMessages = []
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped lang="scss">
.live-conversation {
  @apply flex flex-col min-h-screen relative;
  &__header {
    @apply flex items-center shadow-banner rounded-2xl py-2 px-3 lg:px-5;
  }

  &__user {
    @apply flex flex-auto;
    .user-text {
      @apply flex flex-col justify-center;
      &__name {
        @apply text__headline-4-xs--bold lg:text__body-1-md--semibold text-body-1;
      }

      &__course {
        @apply text__caption-xs--regular lg:text__body-3-md--regular;
      }
    }
  }

  &__message {
    @apply rounded-2xl text__headline-5-xs--regular lg:text__body-1-md--regular
    p-4 mb-2 max-w-[80%] w-[fit-content] relative;
    &--host {
      @apply bg-teal-200 ml-2;
      &::before {
        content: '';
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 30px solid;
        @apply absolute -left-2 top-[-7px] rotate-[60deg];
        @apply border-b-teal-200;
      }
    }

    &--guest {
      @apply bg-purple-200 text-right ml-[auto] mr-2;
      &::after {
        content: '';
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 30px solid;
        @apply absolute -right-4 top-[-7px] rotate-[60deg];
        @apply border-b-purple-200;
      }
    }
  }

  &__message-time {
    @apply text__caption-xs--semibold lg:text__body-3-md--semibold text-teal mb-2;
    &.guest {
      @apply text-right text-purple;
    }
  }

  &__footer {
    @apply w-full bg-white  lg:bottom-6 bottom-6 lg:right-6 lg:left-6 right-4 left-4;
  }

  &__body {
    @apply flex-grow overflow-y-auto mt-6 lg:mt-8 overflow-x-hidden;
    max-height: calc(100vh - 250px);
  }
  &__mobile-btn{
    @apply absolute right-4 bottom-3 rounded-full bg-primary w-[32px] h-[32px] flex justify-center items-center;
  }
}
</style>
