<template>
  <div>
    <div v-show="!hideButton && !messageLoading" class="flex justify-center">
      <BaseButton
        flat
        size="xs-md"
        :label="$t('common.actions.show_more')"
        color="primary"
        @click="$emit('moreMessage')"
      />
    </div>
    <div v-show="messageLoading" class="flex items-center justify-center h-[28px] lg:h-[44px]">
      <BaseLoadingSpinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseLoadingSpinner from '@design-system/base/loading/loading-spinner.vue'

export default {
  name: 'ConversationShowMore',
  components: { BaseLoadingSpinner },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('liveChat', ['getMoreMessageLoading']),
    messageLoading () {
      return this.getMoreMessageLoading
    }
  }
}
</script>

<style scoped>

</style>
