<template>
  <BaseDialog
    :ref="name"
    width="sm"
    height="auth"
    mobile-height="full"
    :name="name"
    class="auth-dialog"
    :show-default-header="false"
  >
    <template #header>
      <AuthDialogHeaderXs
        :title="$t('common.titles.forget_password')"
        @hide="hide"
      />
    </template>
    <template #body>
      <SectionAuthSignup
        v-if="step === steps[0]"
        wrapper="dialog"
        @hide="hide"
        @submitted="submitEmail"
      />
      <SectionAuthVerifyEmail
        v-if="step === steps[1]"
        wrapper="dialog"
        @signupClicked="step = steps[0]"
        @submitted="verifyEmail"
      />
      <SectionAuthSetPassword
        v-if="step === steps[2]"
        wrapper="dialog"
        :allow-code="allowCode"
        @signupClicked="step = steps[0]"
        @submitted="setPassword"
      />
    </template>
  </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionAuthSignup from '@design-system/section/auth/auth-signup'
import SectionAuthVerifyEmail from '@design-system/section/auth/auth-verify-email'
import SectionAuthSetPassword from '@design-system/section/auth/auth-set-password'
import ActionHelper from '@helper/action-after-login'
import AuthDialogHeaderXs from './auth-dialog-header-xs'

export default {
  name: 'SectionAuthDialog',
  components: {
    SectionAuthSignup,
    SectionAuthVerifyEmail,
    SectionAuthSetPassword,
    AuthDialogHeaderXs
  },
  mixins: [ActionHelper],
  data () {
    return {
      steps: [
        'EMAIL',
        'VERIFY',
        'SET_PASSWORD'
      ],
      step: 'EMAIL',
      allowCode: null
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs']),
    name () {
      return this.allDialogs.AUTH_FORGET_PASSWORD
    }
  },
  methods: {
    hide () {
      this.$refs[this.name].hide()
    },
    submitEmail (isRegistered) {
      this.step = this.steps[1]
    },
    verifyEmail (allowCode) {
      this.allowCode = allowCode
      this.step = this.steps[2]
      this.$store.commit('authentication/UPDATE_PASSWORD_TYPE', 'forget')
    },
    async setPassword () {
      await this.hide()
      this.action()
    }
  }
}
</script>

<style scoped>

</style>
