<template>
  <div class="radio-button-group" :class="[messageTypeBorder]">
    <div v-if="title" class="radio-button-group__title">
      {{ title }}
    </div>
    <div class="radio-button-group__options" :class="['type-' + type]">
      <BaseRadioButton
        v-for="option in options"
        :key="option.value"
        v-model="localValue"
        :value="option.value"
        :label="option.name"
        :name="name"
      />
    </div>
    <div
      v-if="message"
      :class="messageTypeText"
      class="base-textarea__message"
    >
      <span class="flex items-center space-x-2">
        <BaseIcon name="info" size="xs" :color="messageType" />
        <span>{{ message }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioButtonGroup',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'block'
    },
    message: {
      type: String,
      default: ''
    },
    messageType: {
      type: String,
      default: '',
      valid: ['warning', 'error']
    }
  },
  data () {
    return {
      localValue: ''
    }
  },
  computed: {
    messageTypeText () {
      return this.messageType === 'warning' ? 'text-warning' : 'text-error'
    },
    messageTypeBorder () {
      if (!this.message) {
        return ''
      } else {
        return 'radio-button-group--' + this.messageType
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'setUpdateValue'
    },
    localValue (val) {
      this.$emit('update', val)
    }
  },
  methods: {
    setUpdateValue (value) {
      this.localValue = value
    }
  }
}
</script>

<style scoped lang="scss">
.radio-button-group {
  // TODO : improve selected area
  &--warning {
    @apply  border-warning;
    &:focus,&:focus-visible {
      @apply  border-warning;
    }
  }
  &--error {
    @apply border-error;
    &:focus,&:focus-visible {
      @apply  border-error;
    }
  }
  &__title {
    @apply m-1 mb-2 mt-0 lg:text__title-input-md--regular text__title-input-md--regular text-body-2;
  }
  &__options {
    &.type-inline {
      @apply flex lg:space-x-6 justify-between lg:justify-start w-full lg:w-auto;
      .radio-button {
        @apply m-0;
      }
    }
  }
}
</style>
