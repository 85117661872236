import Vue from 'vue'

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
Vue.directive('UFirstLetter', {
  bind (el, binding, vnode) {
    const string = el.innerText
    el.innerText = string.charAt(0).toUpperCase() + string.slice(1)
  }
})
Vue.directive('for-callback', function (el, binding) {
  const element = binding.value
  if (element.isLastItem && element.isLastDate) {
    if (typeof element.callback === 'function') {
      element.callback()
    }
  }
})
