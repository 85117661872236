// plugin/loadStore.js
// - List of shared stores
import Global from '@store/global'
import Local from '@store/public-package'
import Teacher from '@store/teacher'
import Course from '@store/course'
import Dialog from '@store/dialog'
import Auth from '@store/auth'
import SubmitRequest from '@store/tutor-request'
import LiveChat from '@store/live-chat'

import index from '@store/index'
export default ({ store }) => {
  store.registerModule('index', index)
  store.registerModule('teacher', Teacher)
  store.registerModule('global', Global)
  store.registerModule('local', Local)
  store.registerModule('course', Course)
  store.registerModule('dialog', Dialog)
  store.registerModule('authentication', Auth)
  store.registerModule('submitRequest', SubmitRequest)
  store.registerModule('liveChat', LiveChat)
}
