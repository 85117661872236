<template>
  <BaseTextField
    :value="search"
    v-bind="$attrs"
    :icon-right="icon"
    dense
    @update="updateSearch"
    @iconRightClicked="iconClicked"
    @keyup="keyupField"
  />
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'SearchField',
  data () {
    return {
      search: '',
      isTyping: true
    }
  },
  computed: {
    icon () {
      return this.search ? 'clear' : 'search'
    }
  },
  watch: {
    search: debounce(function () {
      this.isTyping = false
    }, 1000),
    isTyping (value) {
      if (!value) {
        if (this.search.length > 1) {
          this.$emit('typingFinished', this.search)
        } else {
          this.$emit('typingFinished', '')
        }
      }
    }
  },
  methods: {
    updateSearch (val) {
      this.search = val
    },
    keyupField () {
      this.isTyping = true
    },
    iconClicked () {
      if (this.search) {
        this.search = ''
        this.$emit('clearClicked')
      }
    }
  }
}
</script>

<style scoped>

</style>
