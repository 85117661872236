<template>
  <div class="expansion-text">
    <div class="relative">
      <p
        :class="height"
        class="expansion-text__content text-body-3-caption"
      >
        <slot name="content">
          {{ text }}
        </slot>
        <span v-if="isClosed && showAction" class="expansion-text__border" />
      </p>
    </div>
    <slot name="action">
      <BaseButton
        v-if="showAction"
        class="mt-2"
        :label="label"
        color="primary"
        flat
        size="xs"
        @click="toggle"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ExpansionText',
  props: {
    text: {
      type: String,
      default: ''
    },
    heightDefault: {
      type: String,
      default: 'max-h-12'
    },
    showAction: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      height: 'max-h-12'
    }
  },
  computed: {
    isClosed () {
      return this.height === this.heightDefault
    },
    label () {
      return this.isClosed ? 'Read more' : 'Read less'
    }
  },
  mounted () {
    this.height = this.heightDefault
  },
  methods: {
    toggle () {
      this.height = this.height === this.heightDefault ? 'max-h-[2000px]' : this.heightDefault
    }
  }

}
</script>

<style scoped lang="scss">
.expansion-text {
  &__content {
    @apply transition-all delay-300 overflow-hidden;
   }
  &__border {
    @apply h-3 absolute inset-x-0 bottom-0 w-full;
    transition: all 0.2s ease-in-out;
    background: linear-gradient(180deg, hsl(0deg 0% 100% / 53%) 0%, #ffffff 100%);
  }
}
</style>
