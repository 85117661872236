export default {
  computed: {
    connectionStatus () {
      return this.$nuxt.isOffline
    }
  },
  watch: {
    connectionStatus (offline) {
      if (offline) {
        // hide the online toast if it exists
        this.$toast.global.app_error({
          message: this.$t('common.messages.user_connection_fail')
        })

        // that doesn't hide on its own
      } else {
        // hide the offline toast if it exists
        this.$toast.global.app_success({
          message: this.$t('common.messages.user_connection_success')
        })
      }
    }
  }
}
