/**
 * @see [please read this config before use](https://www.tele rik.com/blogs/api-factories-vue-nuxt)
 * @param $axios
 * @param $toast
 * @param redirect
 * @param app
 * @param store
 * @param $logger
 */
import LRU from 'lru-cache'
import { cacheAdapterEnhancer } from 'axios-extensions'
import {
  validationBackend,
  minuteToMilliSecond
} from '@helper/utils.js'
// const ONE_HOUR = 1000 * 60 * 60

export default function ({
  $axios,
  ssrContext,
  app,
  $logger,
  error,
  route,
  redirect,
  i18n
}) {
  const defaultCache = process.server
    ? ssrContext.$axCache
    : new LRU({ ttl: minuteToMilliSecond(60), maxSize: 10000, ttlAutopurge: true })

  const defaults = $axios.defaults
  $axios.setHeader('X-Localization', i18n.locale)
  $axios.setHeader('X-Timezone', new Date().getTimezoneOffset())
  // https://github.com/kuitos/axios-extensions
  defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache
  })

  $axios.onRequest((config) => {
    if (app.$auth.loggedIn) {
      config.useCache = false
    }
    if (app.$config.mode === 'development') {
      console.log(config.url)
    }
    return config
  })
  $axios.onResponse((response) => {
    return response
  })
  $axios.onError((err) => {
    if (app.$config.mode === 'development') {
      console.log(err)
    }
    if (!err.response) {
      app.$logger.captureException({ error: err, type: 'fetch-error' })
      return
    }
    const code = parseInt(err.response && err.response.status)
    const response = err.response
    const { data } = response
    if (!data) {
      $logger.captureException({ error: err, type: 'fetch-error' })
      return
    }
    const { message } = data
    if (err.response.config.errorType === 'page') {
      if ([301, 302].includes(code)) {
        redirect(code, data.target_url)
        return
      } else if (code === 401) {
        const redirectToLogin = () => {
          if (route.path !== app.localePath(app.$routes.login.path)) {
            redirect({ path: app.localePath(app.$routes.login.path), query: { redirect_url: route.fullPath } })
          }
        }
        if (app.$auth.loggedIn) {
          app.$auth.logout().finally(() => {
            redirectToLogin()
          })
          return
        } else {
          redirectToLogin()
        }
      } else {
        error({
          statusCode: code,
          message: message || 'not found end point or method'
        })
      }
    }
    const url = err.response.config?.url
    if (err.response.config.errorType === 'toast' || (url && url.includes('auth'))) {
      if (app.$toast) {
        validationBackend(err, (name, value) => {
          app.$toast.global.app_error({
            message: value
          })
        })
      }
      return
    }
    if (err.response.config.errorType === 'none') {
      return response
    }
    return err
  })
}
