<template>
  <div class="h-full overflow-y-auto">
    <div v-if="getMobileMenuState.menu ==='default'" class="flex flex-col  justify-start p-6 pr-10 h-full">
      <!--          navigations-->
      <MegaMenuMobileNav v-if="checkData" class="mb-7" @itemClicked="changeContent" />

      <SidebarNavigations
        :navigation-list="navigationList"
        @navigationClicked="$emit('navigationClicked', $event)"
      />
      <!--          contacts-->
      <SocialMedias />
    </div>
    <div v-else>
      <MegaMenuMobile v-if="checkData" />
    </div>
  </div>
</template>

<script>
import SidebarNavigations
from '@design-system/section/sidebar/sidebar-content/sidebar-navigations/sidebar-navigations.vue'
import SocialMedias from '@design-system/section/sidebar/sidebar-content/sidebar-medias/social-medias.vue'
import { mapGetters } from 'vuex'
import MegaMenuMobileNav from '@design-system/base/mega-menu/mega-menu-mobile-nav.vue'
import MegaMenuMobile from '@design-system/base/mega-menu/mega-menu-mobile.vue'

export default {
  name: 'SidebarContent',
  components: {
    SidebarNavigations,
    MegaMenuMobileNav,
    SocialMedias,
    MegaMenuMobile
  },
  props: {
    navigationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      menuContent: true
    }
  },
  computed: {
    ...mapGetters('global', ['getMegaMenu', 'getMobileMenuState']),
    checkData () {
      return this.getMegaMenu && this.getMegaMenu.children
    }
  },
  methods: {
    changeContent (data) {
      data.e.stopPropagation()
      this.menuContent = !this.menuContent
      this.$emit('changeTitle', data.title)
    }
  }

}
</script>

<style scoped>

</style>
