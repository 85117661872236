<template>
  <div ref="dropdown" :class="extraClass">
    <slot name="activator" :toggleDropdown="toggleDropdown" />
    <transition name="drop">
      <div v-if="dropOpen" class="dropdown" :class="[listPosition,wrapperClass]">
        <slot name="dropdown" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown',
  props: {
    dropdownPosition: {
      type: String,
      default: '-bottom-2 translate-y-full right-0'
    },
    extraClass: {
      type: String,
      default: 'relative'
    },
    wrapperClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dropOpen: false,
      positionAuto: '-bottom-2 translate-y-full'
    }
  },
  computed: {
    listPosition () {
      return this.dropdownPosition === 'auto' ? this.positionAuto : this.dropdownPosition
    }
  },
  watch: {
    dropOpen (val) {
      if (val) {
        document.addEventListener('click', this.closeDropdown)
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeDropdown)
    window.addEventListener('scroll', this.removeScroll)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleDropdown () {
      this.dropOpen = !this.dropOpen
      if (!this.dropOpen) {
        this.$emit('dropDownClosed')
      }
    },
    closeDropdown (e) {
      if (!this.$el.contains(e.target)) {
        this.dropOpen = false
        document.removeEventListener('click', this.closeDropdown)
        this.$emit('dropDownClosed')
      }
    },
    handleScroll () {
      if (this.$refs.dropdown) {
        const r = this.$refs.dropdown.getBoundingClientRect()
        if (r.top > 300) {
          this.positionAuto = 'bottom-10'
        } else {
          this.positionAuto = '-bottom-2 translate-y-full'
        }
      }
    },
    removeScroll () {
      this.positionAuto = '-bottom-2 translate-y-full'
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  @apply absolute flex flex-col items-start p-4 shadow-banner  z-10
  bg-white rounded-2xl;
}

.drop-enter-active {
  @apply transition-all duration-200 ease-in;
}
.drop-leave-active {
  @apply transition-all duration-200 ease-in-out;
}
.drop-enter,
.drop-leave-to {
  @apply bottom-5 opacity-0;
}

</style>
