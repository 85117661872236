<template>
  <component
    :is="tag"
    class="py-3 rounded-lg flex items-center justify-center"
    v-bind="attr"
    v-on="$listeners"
  >
    <slot v-if="!loading" />
    <span v-else>
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white fill-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-0"
          cx="12"
          cy="12"
          r="10"
          fill="currentColor"
          stroke-width="4"
        />
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    </span>
  </component>
</template>

<script>
export default {
  name: 'BaseButtonCustom',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button',
      validate: ['button', 'a', 'nuxtLink']
    },
    to: {
      type: String,
      default: ''
    }
  },
  computed: {
    attr () {
      if (this.tag === 'nuxt-link') {
        return { to: this.to }
      }
      if (this.tag === 'a') {
        return { href: this.to } // TODO check this options in btn tag a , target: '_blank'
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
