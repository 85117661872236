<template>
  <div class="unread-message">
    <BaseIcon
      size="md"
      name="chat"
      color="color-icon"
    />
    <span v-if="messageCount > 0" class="unread-message__badge">{{ messageCount }}</span>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionLiveChatUnreadMessage',
  computed: {
    ...mapGetters('liveChat', ['getUnreadMessage']),
    messageCount () {
      return this.getUnreadMessage
    }
  },
  methods: {
    ...mapActions('liveChat', ['fetchUnreadMessage'])
  }
}
</script>

<style scoped lang="scss">
.unread-message {
  @apply w-[24px] relative h-[24px] cursor-pointer;
  &__badge {
    @apply absolute -top-[4px] -right-[3px] rounded-full w-[15px] h-[15px] bg-pink text-white
    text__caption-1-xs--semibold flex items-center justify-center;
  }
}
</style>
