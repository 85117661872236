<template>
  <BaseDialog
    :ref="name"
    width="sm"
    height="auth"
    mobile-height="full"
    :name="name"
    class="auth-dialog-signup"
    :show-default-header="false"
  >
    <template #header>
      <AuthDialogHeaderXs :title="$t('common.titles.signup')" @hide="hide" />
    </template>
    <template #body>
      <transition
        name="fade-x"
        mode="out-in"
        tag="div"
        class="login-modal"
      >
        <SectionAuthSignup
          v-if="step === steps[0]"
          wrapper="dialog"
          class="h-full lg:h-[480px]"
          @hide="hide"
          @submitted="submitEmail"
        />
        <SectionAuthVerifyEmail
          v-if="step === steps[1]"
          wrapper="dialog"
          class="h-full lg:h-[480px]"
          @signupClicked="step = steps[0]"
          @submitted="verifyEmail"
        />
        <SectionAuthSetPassword
          v-if="step === steps[2]"
          wrapper="dialog"
          :allow-code="allowCode"
          @signupClicked="step = steps[0]"
          @submitted="setPassword"
        />
      </transition>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionAuthSignup from '@design-system/section/auth/auth-signup'
import SectionAuthVerifyEmail from '@design-system/section/auth/auth-verify-email'
import SectionAuthSetPassword from '@design-system/section/auth/auth-set-password'
import ActionHelper from '@helper/action-after-login'
import AuthDialogHeaderXs from './auth-dialog-header-xs'

export default {
  name: 'SectionAuthDialog',
  components: {
    SectionAuthSignup,
    SectionAuthVerifyEmail,
    SectionAuthSetPassword,
    AuthDialogHeaderXs
  },
  mixins: [ActionHelper],
  data () {
    return {
      steps: [
        'SIGNUP',
        'VERIFY',
        'SET_PASSWORD'
      ],
      step: 'SIGNUP',
      isRegistered: false,
      allowCode: null
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs']),
    ...mapGetters('authentication', ['getOauthProvider']),
    name () {
      return this.allDialogs.AUTH_SIGNUP
    }
  },
  mounted () {
    if (this.getOauthProvider.length === 0) {
      this.fetchOauthProvider()
    }
  },
  methods: {
    ...mapActions('authentication', ['fetchOauthProvider']),
    hide () {
      this.step = this.steps[0]
      this.$refs[this.name].hide()
    },
    submitEmail (isRegistered) {
      this.step = this.steps[1]
      this.isRegistered = isRegistered
    },
    async verifyEmail (allowCode) {
      this.allowCode = allowCode
      if (this.isRegistered) {
        await this.hide()
        this.action()
      } else {
        this.$store.commit('authentication/UPDATE_PASSWORD_TYPE', 'new')
        this.step = this.steps[2]
      }
    },
    async setPassword () {
      await this.hide()
      this.action()
    }
  }
}
</script>

<style scoped>

</style>
