export function getEmailRegistration (state) {
  return state.emailRegistration
}
export function getOauthProvider (state) {
  return state.oauthProvider
}
export function auth_origin (state) {
  return state.origin
}
export function getActionAfterLogin (state) {
  return state.actionAfterLogin
}
export function getNewPasswordType (state) {
  return state.newPasswordType
}
