export default function () {
  return {
    teacherList: [],
    selectedTeacher: {},
    teacherCalendar: {},
    courseGroups: [],
    courses: [],
    resumeItems: {},
    feedbacks: {},
    tutorSelect: {}
  }
}
