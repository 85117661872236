<template>
  <section>
    <BaseDrawer>
      <template #activator="{toggleSidebar}">
        <BaseIcon name="menu" size="md" color="color-icon" @click.native="toggleSidebar" />
      </template>
      <template #close>
        <div class="py-6 px-6 cursor-pointer bg-primary flex items-center rounded-tr-2xl space-x-10">
          <BaseIcon name="close" size="lg" color="white" />
          <BaseAppLogo width="92" height="24" type="logo-name" color="white" />
        </div>
        <div v-if="getMobileMenuState.menu === 'megaMenu'" class="flex mt-2 px-6" @click="changeState($event)">
          <BaseIcon name="arrow_back" size="md" color="color-icon" class="mr-4" />
          <span>{{ getMobileMenuState.title }}</span>
        </div>
      </template>
      <template #sidebar>
        <SidebarContent
          :navigation-list="navigationList"
          @navigationClicked="$emit('navigationClicked', $event)"
        />
      </template>
    </BaseDrawer>
  </section>
</template>

<script>

import SidebarContent from '@design-system/section/sidebar/sidebar-content/sidebar-content.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionSidebar',
  components: { SidebarContent },
  inject: {
    // provide from header.vue
    navigationList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showTitle: false,
      title: ''
    }
  },
  computed: {
    ...mapGetters('global', ['getMobileMenuState', 'getMegaMenu'])
  },
  methods: {
    ...mapActions('global', ['changeMobileMenu']),
    changeState (e) {
      e.stopPropagation()
      if (this.getMobileMenuState.layout === 'children') {
        const state = {
          menu: 'megaMenu',
          layout: 'firstLevel',
          title: this.getMegaMenu.anchor
        }
        this.changeMobileMenu(state)
      } else {
        const state = {
          menu: 'default',
          layout: '',
          title: ''
        }
        this.changeMobileMenu(state)
      }
    }
  }

}
</script>

<style scoped>

</style>
