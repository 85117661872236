<template>
  <div class="auth-login">
    <div>
      <BaseTitleAuth :title="$t('common.titles.login')" class="mb-6" :class="{'lg-block-hidden': wrapper === 'dialog'}" />
      <BlockAuthLoginThirdParty />
    </div>
    <div class="pt-6">
      <client-only>
        <ValidationProvider ref="login" v-slot="{ errors }" name="email" rules="required|email">
          <BaseTextField
            v-model.trim="email"
            name="email"
            :title="$t('common.titles.email')"
            :placeholder="$t('common.placeholders.email')"
            input-class="w-full"
            :message="errors[0]"
            message-type="error"
            @keyupEnter="submit"
          />
        </ValidationProvider>
        <BaseTextField
          v-model="password"
          name="password"
          :title="$t('common.titles.password')"
          :placeholder="$t('common.placeholders.password')"
          input-class="w-full"
          class=" mt-6"
          :type="passwordType ? 'password': 'text'"
          :icon-right="passwordType ? 'visibility' : 'visibility_off'"
          @iconRightClicked="passwordType = !passwordType"
          @keyupEnter="submit"
        />
        <div class="mt-2 flex justify-start">
          <BaseButton
            v-if="wrapper === 'page'"
            flat
            :label="$t('common.actions.forget_password')"
            size="xs-light"
            :to="localePath({name: $routes.forget_password.name, query: $route.query})"
            tag="a"
            rel="nofollow"
          />
          <BaseButton
            v-if="wrapper === 'dialog'"
            flat
            :label="$t('common.actions.forget_password')"
            size="xs-light"
            @click="AuthDialogChange('AuthDialogForgetPassword')"
          />
        </div>
        <!--      <div classes="auth-login__remember-me">
        <BaseCheckbox light>
          <span classes="text-body-2 text__headline-5-xs&#45;&#45;regular">{{ $t('actions.remember_me') }}</span>
        </BaseCheckbox>
      </div>-->
        <BaseButton
          class="w-full mt-6"
          :label="$t('common.actions.login')"
          size="md"
          :loading="loading"
          @click="submit"
        />
        <BlockAuthTextAction
          v-if="wrapper === 'page'"
          :text="$t('common.text.not_have_account')"
          :action="$t('common.actions.signup')"
          :link="localePath({name: $routes.signup.name, query: $route.query})"
        />
        <BlockAuthTextAction
          v-if="wrapper === 'dialog'"
          :text="$t('common.text.not_have_account')"
          :action="$t('common.actions.signup')"
          @clicked="AuthDialogChange('AuthDialogSignup')"
        />
        <BaseButton
          outline
          :label="$t('common.actions.signup_tutor')"
          class="mx-auto w-[fit-content]"
          size="sm"
          shadow
          tag="a"
          :to="$links.absolute($nuxt, 'start_teaching')"
        />
      </client-only>
    </div>
  </div>
</template>

<script>
import BaseTitleAuth from '@design-system/base/title/title-auth'
import BlockAuthLoginThirdParty from '@design-system/block/auth/auth-login-third-party'
import BlockAuthTextAction from '@design-system/block/auth/auth-text-action'
import { mapActions } from 'vuex'
export default {
  name: 'SectionAuthLogin',
  components: {
    BlockAuthTextAction,
    BlockAuthLoginThirdParty,
    BaseTitleAuth
  },
  props: {
    wrapper: {
      type: String,
      default: 'page'
    }
  },
  data () {
    return {
      email: '',
      password: '',
      passwordType: 'password',
      loading: false
    }
  },
  methods: {
    ...mapActions('dialog', ['hideDialog']),
    ...mapActions('authentication', ['loginByPassword']),
    async submit () {
      const res = await this.$refs.login.validate()
      if (res.valid) {
        this.loading = true
        const email = this.email
        const password = this.password
        const auth_method = 'COOKIE_AND_TOKEN'
        try {
          const data = await this.loginByPassword({
            email,
            auth_method,
            password
          })
          if (data?.token) {
            this.$toast.global.app_success({
              message: this.$t('common.messages.login_success')
            })
            this.$emit('submitted', data)
          }
        } catch (e) {
        } finally {
          this.loading = false
        }
      } else {
        this.$toast.global.app_error({
          message: res.errors[0]
        })
      }
    },
    AuthDialogChange (to) {
      this.$emit('hide')
      setTimeout(() => {
        this.$nuxt.$emit(to)
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.auth-login {
  @apply divide-y divide-border-cart flex flex-col space-y-6;
  &__remember-me {
    @apply mt-4 lg:mt-6 flex lg:justify-center justify-start;
  }
}
</style>
