<template>
  <div
    :class="[`icon-${color}`]"
    class="flex space-x-3 cursor-pointer items-center"
  >
    <span
      :class="[
        `material-icons-${mode}`,
        `icon-${size}`,
        `gradient-${gradient}`,
      ]"
    >
      {{ name }}
    </span>
    <span :class="labelClass">{{ label }}</span>
  </div>
</template>

<script>
import 'material-icons/iconfont/material-icons.css'

export default {
  name: 'BaseListItem',
  props: {
    name: {
      type: String,
      default: 'user'
    },
    color: {
      type: String,
      default: 'primary'
    },
    mode: {
      type: String,
      default: 'round',
      validate: ['round', 'outlined', 'sharp']
    },
    size: {
      type: String,
      default: 'md',
      validate: ['xs', 'sm', 'md', 'lg', 'xl']
    },
    gradient: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "list-item-mixins";

$colors: primary primary,
primary-700 primary-700,
icon-text-field icon-text-field-hover;
$sizes: xs 16px 16px,
sm 20px 20px,
md 24px 24px,
lg 32px 32px,
xl 48px 48px,
xs-sm 16px 20px;
@include color($colors);
@include size($sizes);
@include gradient($gradients);
</style>
