<template>
  <div class="auth-set-password" :class="{'auth-set-password--dialog': wrapper === 'dialog'}">
    <div>
      <BaseTitleAuth
        :title="title"
        :class="{'lg-block-hidden': wrapper === 'dialog'}"
      />
    </div>
    <div class="auth-set-password__email">
      <span>{{ email }}</span>
    </div>
    <client-only>
      <ValidationObserver ref="form">
        <ValidationProvider v-slot="{ errors }" name="password" rules="required|min:6|confirmed:confirmation">
          <BaseTextField
            v-model="password"
            name="password"
            :type="passwordType ? 'password': 'text'"
            :title="$t('common.titles.password')"
            :placeholder="$t('common.placeholders.password')"
            input-class="w-full"
            :message="errors[0]"
            message-type="error"
            :icon-right="passwordType ? 'visibility' : 'visibility_off'"
            @iconRightClicked="passwordType = !passwordType"
            @keyupEnter="submit"
          />
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="confirm_password" vid="confirmation">
          <BaseTextField
            v-model="passwordConfirm"
            name="confirm_password"
            :type="passwordConfirmType ? 'password': 'text'"
            :title="$t('common.titles.confirm_password')"
            :placeholder="$t('common.placeholders.confirm_password')"
            input-class="w-full"
            :message="errors[0]"
            message-type="error"
            class="mt-8"
            :icon-right="passwordConfirmType ? 'visibility' : 'visibility_off'"
            @iconRightClicked="passwordConfirmType = !passwordConfirmType"
            @keyupEnter="submit"
          />
        </ValidationProvider>
      </ValidationObserver>

      <BaseButton
        class="w-full mt-8"
        :label="labelAction"
        size="lg"
        :loading="loading"
        shadow
        @click="submit"
      />
    </client-only>
  </div>
</template>

<script>
import BaseTitleAuth from '@design-system/base/title/title-auth'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SectionAuthSetPassword',
  components: {
    BaseTitleAuth
  },
  props: {
    wrapper: {
      type: String,
      default: 'page'
    },
    allowCode: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      password: '',
      passwordConfirm: '',
      loading: false,
      passwordType: true,
      passwordConfirmType: true
    }
  },
  computed: {
    ...mapGetters('authentication', ['getNewPasswordType']),
    email () {
      return this.$auth.$storage.getUniversal('EMAIL_REGISTRATION')
    },
    forget () {
      return this.$route.query?.type === 'forget' || this.getNewPasswordType === 'forget'
    },
    title () {
      return this.forget ? this.$t('common.titles.set_new_password') : this.$t('common.titles.set_password')
    },
    labelAction () {
      return this.forget ? this.$t('common.actions.reset_password') : this.$t('common.actions.create_account')
    },
    message () {
      return this.forget ? this.$t('common.messages.set_new_password_success') : this.$t('common.messages.set_password_success')
    }
  },
  methods: {
    ...mapActions('authentication', ['setPassword']),
    async submit () {
      const res = await this.$refs.form.validate()
      if (res) {
        this.loading = true
        const password = this.password
        const password_confirmation = this.passwordConfirm
        const allow_code = Number(this.$route.query?.p || this.allowCode)
        try {
          await this.setPassword({
            password,
            password_confirmation,
            allow_code
          })
          this.$toast.global.app_success({
            message: this.message
          })
          this.$emit('submitted')
        } catch (e) {
        } finally {
          this.loading = false
        }
      } else {
        this.$toast.global.app_error({
          message: res.errors[0]
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.auth-set-password {
  @apply flex flex-col space-y-8 justify-center h-full;
  &--dialog {
    @apply justify-start;
  }
  &__email {
    @apply flex justify-center;
  }
  &__text {
    @apply text__headline-5-xs--regular text-center;
  }
}
</style>
